import React, { useState } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import EmployeeSalaryList from './EmployeeSalaryList'
import EmployeeCTCDetail from './EmployeeCTCDetail'

const SingleEmployeeSalary = () => {
    const navigate = useNavigate()
    const { id, username } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const changeType = (type) => {
        setSearchParams({ type })
    }
    const [employeeType,setEmployeeType] = useState('')
    const fetchEmployeeType = (type) => {
        setEmployeeType(type)
    }
    return (
        <>
            <div className=' px-4 py-4 flex justify-between sticky bg-white top-16 shadow-lg z-40'>
                <div className='flex items-center space-x-4'>
                    {/* <button onClick={() => navigate(-1, { replace: true })} className='flex items-center hover:underline'>
                        <ChevronLeftIcon className='w-6 h-6' />
                        <span className='font-semibold'>Back</span>
                    </button> */}
                    <h2 className='text-2xl font-semibold'>Salary Details for {username}</h2>
                </div>
                <div className=' space-x-5'>
                    {/* <Link to={`/employee-promotion/${id}/${username}`} className='px-5 py-2 bg-blue-300 rounded font-semibold'>Employee Promotion</Link> */}
                    <Link to={`/employee-salary/${id}/${username}?employeeType=${employeeType}`} className='px-5 py-2 bg-yellow-300 rounded font-semibold'>Add Salary Details</Link>
                </div>
            </div>
            <div className='px-5 border-b border-gray-200 py-2 mt-10 mx-5'>
                <ul className='flex items-center space-x-6 text-gray-600'>
                    <li className={`${(searchParams.get('type') === 'current_type' || !searchParams.get('type')) && 'active'}`}>
                        <button onClick={() => changeType('current_type')}>Current CTC Details</button>
                    </li>
                    <li className={`${searchParams.get('type') === 'previous_type' && 'active'}`}>
                        <button onClick={() => changeType('previous_type')}>Previous CTC Details</button>
                    </li>
                </ul>
            </div>
            {(searchParams.get('type') === 'current_type' || !searchParams.get('type')) && <EmployeeCTCDetail fetchEmployeeType={fetchEmployeeType}/>}
            {searchParams.get('type') === 'previous_type' && <EmployeeSalaryList />}
        </>
    )
}

export default SingleEmployeeSalary


