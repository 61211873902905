import React, { useEffect, useState } from "react";
import Radio from "../../components/radio/Radio";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postLeaveApplicationForm } from "../../api/leaveApplicationForm";
import spinner from "../../assets/images/spinner.gif";
import { useNavigate } from "react-router-dom";
import { subDays, addDays } from "date-fns";
import {
  DISCRETIONAL_LEAVE_YEAR_IGNORE,
  MIN_LEAVE_DATE_FOR_CL,
  MIN_LEAVE_DATE_FOR_PL,
  ROLE,
} from "../../config";
import toast, { Toaster } from "react-hot-toast";

const cookies = new Cookies();

const IS_REQUIRED = true;
function LeaveApplicationForm() {
  let initialState = {
    type_of_leave: "",
    leave_date_from: "",
    leave_date_fromForm: "",
    leave_date_to: "",
    leave_date_toForm: "",
    leave_date_to_min_date: "",
    total_leave: 0,
    reason: "",
  };
  const date = new Date();
  const [role, setRole] = useState("");
  useEffect(() => {
    setRole(cookies.get(ROLE));
  }, []);
  const navigate = useNavigate();
  const [leaveApplicationData, setLeaveApplicationData] =
    useState(initialState);
  const [minDate, setminDate] = useState(
    new Date(moment().add(MIN_LEAVE_DATE_FOR_PL, "days").calendar())
  );
  const [medicalCertificate, setMedicalCertificate] = useState();
  const [dateError, setDateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState("");
  const handleLeaveForm = (e) => {
    let value = e.target.value;
    let data = {
      ...leaveApplicationData,
      [e.target.name]: value,
    };
    setLeaveApplicationData(data);
  };

  const handleLeaves = (e) => {
    let data;
    let value = e.target.value;
    data = {
      ...leaveApplicationData,
      [e.target.name]: value,
      leave_date_from: "",
      leave_date_fromForm: "",
      leave_date_to: "",
      leave_date_toForm: "",
      total_leave: 0,
      reason: "",
    };
    switch (value) {
      case "CL":
        setminDate(subDays(new Date(), 0));
        setNote("Note: Maximum 3 days of leave");
        break;
      case "PL":
        setminDate(subDays(new Date(), 0));
        break;
      case "OL":
        setminDate(
          new Date(moment().add(MIN_LEAVE_DATE_FOR_PL, "days").calendar())
        );
        setNote("");
        break;
      case "DL":
        setminDate(
          subDays(
            new Date().getFullYear() === DISCRETIONAL_LEAVE_YEAR_IGNORE
              ? new Date("2023/01/01")
              : new Date(),
            0
          )
        );
        setNote("");
        break;
      case "SL":
        setminDate(null);
        setNote("Note: Minimum 4 days of leave to be selected");
        data = {
          ...leaveApplicationData,
          ...data,
          reason: "Sick",
        };
        break;
      case "HDL":
        setNote("");
        break;
      default:
        break;
    }
    setLeaveApplicationData(data);
  };
  const handleDate = (value, name) => {
    setDateError(false);
    let data;

    if (
      name === "leave_date_from" &&
      leaveApplicationData.type_of_leave === "HDL"
    ) {
      setLeaveApplicationData({
        ...leaveApplicationData,
        total_leave: 0.5,
      });
    }

    data = {
      ...leaveApplicationData,
      [name]: moment(new Date(value)).format(),
      [`${name}Form`]: value,
    };
    if (leaveApplicationData.type_of_leave === "DL") {
      data = {
        ...leaveApplicationData,
        ...data,
        total_leave: 1,
      };
      setLeaveApplicationData(data);
      return;
    }
    switch (name) {
      case "leave_date_from":
        data = {
          ...leaveApplicationData,
          ...data,
          leave_date_to_min_date: addDays(new Date(value), 0),
          leave_date_toForm: "",
          leave_date_to: "",
          total_leave: leaveApplicationData.type_of_leave === "HDL" ? 0.5 : 0,
        };
        break;
      case "leave_date_to":
        data = {
          ...leaveApplicationData,
          ...data,
          total_leave: handleTotalLeaveCalculation(value),
        };
        break;
      default:
        break;
    }
    setLeaveApplicationData(data);
  };
  const handleMedicalCerificated = (e) => {
    setMedicalCertificate(e.target.files);
  };
  const handleTotalLeaveCalculation = (value) => {
    let totalLeave = 0;
    totalLeave = leaveApplicationData.leave_date_from
      ? moment(value).diff(leaveApplicationData.leave_date_from, "days") + 1
      : 0;
    return totalLeave;
  };
  const handleSubmitLeaveForm = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (validateNumberOfLeaves()) {
      postLeaveApplicationForm(leaveApplicationData, medicalCertificate)
        .then((res) => {
          toast.success("Leaves Applied Successfully");
          setIsLoading(false);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((err) => {
          setIsLoading(false);
          const { status, data } = err.response;
          if (status >= 400 || status <= 499) {
            toast.error(data.message);
          } else {
            toast.error("Internal server error");
          }
        });
    } else {
      setIsLoading(false);
    }
  };

  const validateNumberOfLeaves = () => {
    let isSafe = false;
    const { type_of_leave, total_leave } = leaveApplicationData;
    if (type_of_leave === "SL") return true;
    if (type_of_leave === "HDL") return true;
    if (type_of_leave === "DL") return true;
    if (type_of_leave === "OL") return true;
    if (type_of_leave === "CL" && total_leave <= 3) {
      return (isSafe = true);
    }
    if (type_of_leave === "PL" && total_leave >= MIN_LEAVE_DATE_FOR_PL) {
      return (isSafe = true);
    }
    setDateError(true);
    return isSafe;
  };
  return (
    <>
      <Toaster />
      <div className=" bg-[color:var(--color3)]">
        <div className="container">
          <form onSubmit={handleSubmitLeaveForm}>
            <div className="p-4">
              <div className="mb-4">
                <label
                  htmlFor="date"
                  className="text-xl font-semibold text-gray-700 inline-block "
                >
                  Date:
                </label>
                <span className=" text-xl font-semibold text-gray-700 inline-block ml-3">
                  <Moment format="D MMM YYYY">{date}</Moment>
                </span>
              </div>
              <div className="space-y-4">
                <div className="">
                  <p>
                    <label htmlFor="reason" className="label">
                      Type of leave:
                    </label>
                  </p>
                  <div className=" flex mt-2 space-x-4">
                    {cookies.get(ROLE) === "employee" ? (
                      <>
                        <Radio
                          label={"Priviledge Leave"}
                          name={"type_of_leave"}
                          value={"PL"}
                          onChange={handleLeaves}
                        />
                      </>
                    ) : (
                      <>
                        <Radio
                          label={"Other Leave"}
                          name={"type_of_leave"}
                          value={"OL"}
                          onChange={handleLeaves}
                        />
                      </>
                    )}
                    <Radio
                      label={"Sick Leave"}
                      name={"type_of_leave"}
                      value={"SL"}
                      onChange={handleLeaves}
                    />
                    <Radio
                      label={"Half Day Leave"}
                      name={"type_of_leave"}
                      value={"HDL"}
                      onChange={handleLeaves}
                    />
                  </div>
                </div>
                {leaveApplicationData?.type_of_leave && (
                  <div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div className="">
                        <label htmlFor="leave_date" className="label">
                          Date:
                        </label>
                        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="flex">
                            {leaveApplicationData.type_of_leave !== "HDL" && (
                              <label className=" roboto p-2">From</label>
                            )}
                            <DatePicker
                              name="leave_date_from"
                              autoComplete="false"
                              className="input"
                              dateFormat="dd/MM/yyyy"
                              minDate={minDate}
                              selected={
                                leaveApplicationData?.leave_date_fromForm
                              }
                              onChange={(value) =>
                                handleDate(value, "leave_date_from")
                              }
                            />
                          </div>
                          {leaveApplicationData?.leave_date_from &&
                          leaveApplicationData.type_of_leave !== "DL" &&
                            leaveApplicationData.type_of_leave !== "HDL" && (
                              <div className="flex">
                                <label className=" roboto p-2 mr-[1.3rem] lg:mr-0">
                                  To
                                </label>
                                <DatePicker
                                  className="input"
                                  autoComplete="false"
                                  dateFormat="dd/MM/yyyy"
                                  minDate={
                                    leaveApplicationData?.leave_date_to_min_date
                                  }
                                  selected={
                                    leaveApplicationData?.leave_date_toForm
                                  }
                                  onChange={(value) =>
                                    handleDate(value, "leave_date_to")
                                  }
                                />
                              </div>
                            )}
                        </div>
                        {(leaveApplicationData?.type_of_leave !== "SL" ||
                          role !== "consultant") && (
                          <small
                            className={`inline-block mt-4 font-semibold ${
                              dateError ? "text-red-700" : "text-gray-500"
                            }`}
                          >
                            {note}{" "}
                          </small>
                        )}
                      </div>
                      {leaveApplicationData.type_of_leave !== "HDL" && (
                        <div className="">
                          <label
                            htmlFor="total_leave"
                            className={`label ${
                              dateError ? "text-red-700" : ""
                            }`}
                          >
                            Total Leaves :{" "}
                            {dateError && (
                              <small
                                className={`inline-block font-semibold ${
                                  dateError ? "text-red-700" : "text-gray-500"
                                }`}
                              >
                                Note:{" "}
                                {leaveApplicationData?.type_of_leave === "CL"
                                  ? "Maximum 3 days of leave"
                                  : leaveApplicationData?.type_of_leave === "DL"
                                  ? "Minimum 4 days of leave to be selected"
                                  : ""}{" "}
                              </small>
                            )}
                          </label>
                          <input
                            type="number"
                            name="total_leave"
                            readOnly
                            disabled
                            className={`input disabled:cursor-not-allowed ${
                              dateError && "border-red-700"
                            }`}
                            placeholder="Total leave"
                            value={leaveApplicationData.total_leave}
                          />
                        </div>
                      )}
                      {leaveApplicationData.type_of_leave === "SL" && (
                        <>
                          <div className="">
                            <label
                              htmlFor="medical_certificates"
                              className="label"
                            >
                              Upload Medical Documents:
                            </label>
                            {
                              <small className=" inline-block ml-3 text-red-600">
                                Note: Medical Document is required
                              </small>
                            }
                            <input
                              type="file"
                              multiple
                              required={true}
                              name="medical_certificates"
                              className="input border-none"
                              onChange={handleMedicalCerificated}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className=" mt-4 lg:mt-0">
                      <p>
                        <label htmlFor="reason" className="label">
                          Reason:
                        </label>
                      </p>
                      <textarea
                        id="reason"
                        required={IS_REQUIRED}
                        name="reason"
                        placeholder="Enter Reason here...."
                        rows="4"
                        cols="50"
                        className="text_area"
                        value={leaveApplicationData.reason}
                        onChange={handleLeaveForm}
                      />
                    </div>
                    <div className="">
                      <div className=" space-x-4 text-center my-8">
                        {isLoading ? (
                          <img
                            src={spinner}
                            alt="spinner"
                            className="w-12 h-12 mx-auto"
                          />
                        ) : (
                          <button
                            type="submit"
                            className=" bg-[color:var(--color1)] hover:bg-[color:var(--color5)] text-white  py-3 px-20 rounded-full"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LeaveApplicationForm;
