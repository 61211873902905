import React from 'react'
import Input from '../../components/form/Input'

const PreviousLeaves = ({
    employeeType,
    type,
    data
}) => {
    const { applicableLeaves, user_type } = data
    return (
        <div className=' space-y-5'>
            <>
                <div className="">
                    <label htmlFor="pl_leave" className='label'>Other Leave:</label>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='mt-4'>
                            <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                            <p className='border rounded px-3 py-2 h-10'>
                                {applicableLeaves?.annual_ol}
                            </p>
                        </div>
                        <div className='mt-4'>
                            <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                            <p className='border rounded px-3 py-2 h-10'>
                                {applicableLeaves?.pending_ol_leave}
                            </p>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default PreviousLeaves