import { Link, useParams } from 'react-router-dom'
import { PlusIcon, EyeIcon, PencilAltIcon } from '@heroicons/react/solid';
import { Button } from '../../../components/ui';
import useGetUserLoanListing from '../../../hooks/Loan/useGetUserLoanListing';
import { Toaster } from 'react-hot-toast';
import Pagination from '../../../components/pagination';

const EmployeeLoanListing = () => {
    const { id, username } = useParams()
    const { responseData, paramsObject, handlePageClick } = useGetUserLoanListing()
    return (
        <>
            <Toaster />
            <div className="px-4 pb-4 font-bold shadow-md">
                Loan Records for "{username}"
            </div>
            <div className='px-4 py-4'>
                <div className=" container">
                    <div className=" flex justify-end space-x-2 px-4">
                        <Button className={'px-0 py-0'}>
                            <Link to={`/create-loan/${id}/${username}`} className='px-4 py-2 flex items-center'>
                                <PlusIcon className='w-4 h-4 mr-2' />
                                Add Loan Record
                            </Link>
                        </Button>
                        {/* <Button variant={'ghost'}>
                            <span className=' text-blue-600'>Download Report</span> 
                        </Button> */}
                    </div>
                </div>
                <div class="max-w-full overflow-x-auto px-4 mt-5">
                    <table class="table-auto w-full">
                        <thead>
                            <tr class=" bg-[color:var(--color1)] text-center">
                                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Loan Amount</th>
                                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">No of Month</th>
                                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Date Processed</th>
                                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">EMI Rate</th>
                                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {responseData?.data?.data?.length <= 0 ?
                                <tr>
                                    <td colSpan={5}>
                                        <div className='text-center py-2 text-gray-600'>No Records Found</div>
                                    </td>
                                </tr>
                                : <>
                                    {responseData?.data?.data.map((item, index) => {
                                        const { amount, emi_amount, emi_duration, from_date, id: loanId } = item || {}
                                        return <tr key={index}>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{amount}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{emi_duration}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{from_date}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{emi_amount}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                                <div className='flex items-center justify-center w-full space-x-3'>
                                                    <Link to={`/loan-repayment-log/${id}/${username}/${loanId}`}>
                                                        <EyeIcon className='h-6 text-gray-600 w-6 hover:text-blue-600' />
                                                    </Link>
                                                    <Link to={`/edit-loan/${id}/${username}/${loanId}`}>
                                                        <PencilAltIcon className='h-6 text-gray-600 w-6 hover:text-blue-600' />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </>
                            }
                        </tbody>
                    </table>
                    <div className='px-5 mt-5 mb-10'>
                        <Pagination
                            currentPage={+paramsObject.page_number}
                            lengthofItems={responseData.data?.total_count}
                            limit={+paramsObject.limit}
                            onPageChange={handlePageClick}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeLoanListing