import React, { useEffect, useState } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  XIcon,
  CashIcon,
  LogoutIcon,
  ClipboardIcon,
  ColorSwatchIcon,
  DocumentTextIcon,
  PresentationChartBarIcon,
  SwitchHorizontalIcon,
  IdentificationIcon,
  BookOpenIcon,
  UserGroupIcon,
  HomeIcon,
  DocumentIcon,
} from "@heroicons/react/solid";
import { MdOutlineMoney } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { COMPANY_NAME, LOGO2, ROLE, TOKEN_NAME, USERNAME } from "../config";
import useClickOutside from "../hooks/useClickOutside";
import { Button } from "./ui";

const cookies = new Cookies();

function Sidebar({ show, onClose }) {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  useEffect(() => {
    setRole(cookies.get(ROLE));
  }, []);

  const [moreMenuOpen, setMoreMenuOpen] = useState(null);
  const handleOpenMoreMenu = (value) => {
    setMoreMenuOpen(value);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenuOpen(false);
  };
  const SHIFT = [
    {
      name: "Shifts Creation",
      slug: "/shifts",
    },
    {
      name: "Employee Shift Mapping",
      slug: "/employee-shift-mapping",
    },
    {
      name: "Shifts Rotation",
      slug: "/shift-rotation",
    },
    {
      name: "Settings",
      slug: "/shift-settings",
    },
  ];
  const SALARY = [
    {
      name: "Salary Creation",
      slug: "/salary",
    },
    {
      name: "ESIC",
      slug: "/salary/esic",
    },
    {
      name: "PF",
      slug: "/salary/pf",
    },
  ];
  const sidebarRef = useClickOutside(() => {
    onClose();
  });
  const handleLogout = () => {
    cookies.remove(TOKEN_NAME);
    cookies.remove(ROLE);
    cookies.remove(USERNAME);
    navigate("/login");
  };
  return (
    <>
      <div
        ref={sidebarRef}
        className={`bg-[color:var(--color1)] border-r border-[color:var(--color1)] duration-500 ease-in-out  lg:block w-64 h-screen p-4 overflow-auto top-0 z-[60] right-0 lg:left-0 fixed ${
          show
            ? "right-0 translate-x-0"
            : " translate-x-[500px] lg:translate-x-0"
        }`}
      >
        <div>
          <div className="hidden lg:block">
            <div className="flex items-center space-x-3 mb-5">
              <img src={LOGO2} alt="logo" className="w-10 h-10" />
              <p className=" text-center  text-2xl text-white font-bold roboto">
                <Link to={"/"}>{COMPANY_NAME}</Link>
              </p>
            </div>
          </div>
          <div className="block lg:hidden text-right" onClick={onClose}>
            <Button size={"icon"} variant={"ghost"}>
              <XIcon className="w-6 h-6 text-white" />
            </Button>
          </div>
          <div className=" divide-y divide-white">
            <div className=" text-white text-lg roboto text-center py-2">
              <Link
                to={"/"}
                className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
              >
                <HomeIcon className="w-5" />
                <span className=" text-sm">Home</span>
              </Link>
            </div>
            {(role === "admin" || role === "hr") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/employee-details"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <BriefcaseIcon className="w-5" />
                  <span className=" text-sm">User Details</span>
                </Link>
              </div>
            )}
            {(role === "admin" || role === "hr") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/department"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <UserGroupIcon className="w-5" />
                  <span className=" text-sm">Department</span>
                </Link>
              </div>
            )}
            {(role === "admin" || role === "hr") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/shifts"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <SwitchHorizontalIcon className="w-5" />
                  <span className=" text-sm">Shifts</span>
                </Link>
              </div>
            )}
            {role === "employee" || role === "consultant" ? (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/leave-application"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <DocumentTextIcon className=" w-6" />
                  <span className=" text-sm">Leave Application</span>
                </Link>
              </div>
            ) : null}
            {(role === "admin" || role === "hr") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/leave-applied"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <ClipboardIcon className="w-5" />
                  <span className=" text-sm">Leaves Applied</span>
                </Link>
              </div>
            )}
            {(role === "employee" || role === "consultant") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/leaves"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <ClipboardIcon className="w-5" />
                  <span className=" text-sm">Leaves Applied</span>
                </Link>
              </div>
            )}
            {(role === "admin" || role === "hr") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/salary"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <CashIcon className="w-5" />
                  <span className=" text-sm">Salary</span>
                </Link>
              </div>
            )}
            {(role === "employee" || role === "consultant") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/shift-details"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <SwitchHorizontalIcon className="w-5" />
                  <span className=" text-sm">Shifts Details</span>
                </Link>
              </div>
            )}
            {(role === "admin" || role === "hr") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/employment-management"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <ColorSwatchIcon className="w-5" />
                  <span className=" text-sm">Employment</span>
                </Link>
              </div>
            )}
            {(role === "admin" ||
              role === "hr" ||
              role === "employee" ||
              role === "consultant") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/public-holidays"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <CalendarIcon className="w-5" />
                  <span className=" text-sm">Public Holidays</span>
                </Link>
              </div>
            )}
            {role === "admin" && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/attendance"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <PresentationChartBarIcon className="w-5" />
                  <span className=" text-sm">Attendance</span>
                </Link>
              </div>
            )}
            {role === "admin" && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/web-check-ins"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <IdentificationIcon className="w-5" />
                  <span className=" text-sm">Web Check-ins</span>
                </Link>
              </div>
            )}
            {role === "admin" && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/petty-cash"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <MdOutlineMoney className="w-5" />
                  <span className=" text-sm">Petty Cash</span>
                </Link>
              </div>
            )}
            {role === "employee" && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/attendance-punch-in"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <IdentificationIcon className="w-5" />
                  <span className=" text-sm">Punch in</span>
                </Link>
              </div>
            )}
            {role === "admin" && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/hand-book"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <BookOpenIcon className="w-5" />
                  <span className=" text-sm">Hand Books</span>
                </Link>
              </div>
            )}
            {(role === "employee" || role === "consultant") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/hand-book"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <BookOpenIcon className="w-5" />
                  <span className=" text-sm">Hand Books</span>
                </Link>
              </div>
            )}
            {role === "employee" && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Link
                  to={"/salary-slips"}
                  className=" flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]"
                >
                  <DocumentIcon className="w-5" />
                  <span className=" text-sm">Salary Slips</span>
                </Link>
              </div>
            )}
            {(role === "employee" || role === "consultant") && (
              <div className=" text-white text-lg roboto text-center py-2">
                <Button
                  onClick={handleLogout}
                  variant={"destructive"}
                  className={"w-full"}
                >
                  <LogoutIcon className="w-5 h-5 mr-2" />
                  <span className=" text-sm">Logout</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;

// import { Link, useNavigate } from "react-router-dom";
// import Cookies from 'universal-cookie';
// import { COMPANY_NAME, ROLE, TOKEN_NAME, USERNAME } from '../config';
// import useClickOutside from '../hooks/useClickOutside';
// import { Button } from './ui';

// const cookies = new Cookies

// function Sidebar({ show, onClose }) {
//   const navigate = useNavigate();
//   const [role, setRole] = useState('')
//   useEffect(() => {
//     setRole(cookies.get(ROLE))
//   }, [])

//   const [moreMenuOpen, setMoreMenuOpen] = useState(null)
//   const handleOpenMoreMenu = (value) => {
//     setMoreMenuOpen(value)
//   }

//   const handleCloseMoreMenu = () => {
//     setMoreMenuOpen(false)
//   }
//   const SHIFT = [
//     {
//       name: 'Shifts Creation',
//       slug: '/shifts',
//     },
//     {
//       name: 'Employee Shift Mapping',
//       slug: '/employee-shift-mapping',
//     },
//     {
//       name: 'Shifts Rotation',
//       slug: '/shift-rotation',
//     },
//     {
//       name: 'Settings',
//       slug: '/shift-settings',
//     },
//   ]
//   const SALARY = [
//     {
//       name: 'Salary Creation',
//       slug: '/salary',
//     },
//     {
//       name: 'ESIC',
//       slug: '/salary/esic',
//     },
//     {
//       name: 'PF',
//       slug: '/salary/pf',
//     },
//   ]
//   const sidebarRef = useClickOutside(() => {
//     onClose()
//   })

//   const handleLogout = () => {
//     cookies.remove(TOKEN_NAME)
//     cookies.remove(ROLE)
//     cookies.remove(USERNAME)
//     navigate('/login');
// };
