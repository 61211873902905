// import React, { useCallback, useEffect, useState } from 'react'
// import { getIndividualEmployeeDetail, updateEmployeeDetail } from '../../api/employeeDetails'

// import Layout from '../../components/layout/Layout'
// import Radio from '../../components/radio/Radio'
// import { Link, useParams } from "react-router-dom";
// import Modals from '../../components/modal/Modals';
// import spinner from '../../assets/images/spinner.gif'
// import { generatePassword, taxCalculation } from '../../utility';
// import { Modal } from '@mui/material';
// import { XIcon } from '@heroicons/react/solid';
// import { resetPassword } from '../../api/auth';
// import EmployeePromotionModal from './EmployeePromotionModal';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { FULL_TIME_EMPLOYEE, ON_SIDE, PART_TIME_EMPLOYEE } from '../../config';
// let IS_REQUIRED = true
// let val = [0, 0, 0]
// function AddEmployeeDetails() {
//     const { id, userType } = useParams()
//     const [isLoading, setIsLoading] = useState(false)
//     const [employeDetail, setEmployeDetail] = useState({})
//     const [isShowingModal, setIsShowingModal] = useState(false)
//     const [isConsultant, setIsConsultant] = useState(false)
//     const [isDisabled, setIsDisabled] = useState(false);
//     const [eduationFile, setEducationFile] = useState([])
//     const [regeneratePasswordModal, setRegeneratePasswordModal] = useState(false)
//     const handleSetEmployeDetail = (e) => {
//         const value = e.target.value
//         if (e.target.name === 'cl_leave' || e.target.name === 'pl_leave' || e.target.name === 'dl_leave' || e.target.name === 'sl_leave' || e.target.name === 'ol_leave') {
//             const id = e.target.getAttribute("data-id")
//             val[id] = +value
//             const sum = val.reduce(
//                 (previousValue, currentValue) => previousValue + currentValue,
//                 0,
//             );
//             let data = {
//                 ...employeDetail,
//                 [e.target.name]: +value,
//                 total_leave: sum
//             }
//             setEmployeDetail(data)
//             return data
//         }
//         else {
//             setEmployeDetail({
//                 ...employeDetail,
//                 [e.target.name]: value
//             })
//         }
//         if (e.target.name === 'gst_number') {
//             let data = { ...employeDetail }
//             if (value === '' || !value || value === undefined) {
//                 data = { ...employeDetail, gst: 0, [e.target.name]: value }
//                 let calculatedData = taxCalculation(data)
//                 setEmployeDetail(calculatedData)
//             } else {
//                 data = {
//                     ...employeDetail,
//                     [e.target.name]: value
//                 }
//                 setEmployeDetail(data)
//             }
//         }
//         if (e.target.name === 'date_of_joining') {
//             calculateProbationDate({ value, text: employeDetail.employmentType })
//         }
//         return {
//             ...employeDetail,
//             [e.target.name]: value,
//         }
//     }
//     const handleSubmitEmployeDetail = () => {
//         console.log(employeDetail)
//         updateEmployeeDetail(employeDetail, eduationFile)
//             .then((res) => {
//                 console.log(res)
//                 if (res.status === 200) {
//                     setIsShowingModal(true)
//                 }
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }
//     useEffect(() => {
//         handleFetchIndividualData()
//     }, [])

//     const handleFetchIndividualData = () => {
//         setIsLoading(true)
//         getIndividualEmployeeDetail(id)
//             .then((res) => {
//                 setIsLoading(false)
//                 setEmployeDetail(res.data.data)
//                 if (res.data.data.type_of_employee === 'consultant') {
//                     val[0] = +res.data.data.dl_leave
//                     val[1] = +res.data.data.ol_leave
//                 } else {
//                     val[0] = +res.data.data.cl_leave
//                     val[1] = +res.data.data.pl_leave
//                     val[2] = +res.data.data.sl_leave
//                 }
//             })
//             .catch((err) => {
//                 console.log(err)
//                 setIsLoading(false)
//             })
//     }
//     const handleTax = (e) => {
//         let data = taxCalculation(handleSetEmployeDetail(e))
//         setEmployeDetail(data)
//         // console.log(data)
//     }
//     const [newPassword, setNewPassword] = useState('')
//     const [isResettingPassword, setResettingPassword] = useState(false)
//     const handleGeneratePassword = () => {
//         var password = generatePassword({
//             uppercase: true,
//             lowercase: true,
//             symbols: true,
//             numbers: true
//         })
//         setNewPassword(password)
//     }

//     const handleSetNewPassword = (e) => {
//         setResettingPassword(true)
//         e.preventDefault()
//         resetPassword(id, newPassword)
//             .then((res) => {
//                 if (res.status === 200) {
//                     setResettingPassword(false)
//                     setRegeneratePasswordModal(false)
//                 }
//             })
//             .catch((err) => {
//                 setResettingPassword(false)
//                 alert("Something went wrong please try again later")
//             })
//     }

//     const [isOpenEmployeePromotionModal, setIsOpenEmployeeModal] = useState(false)
//     const handleDate = (value) => {
//         setEmployeDetail(prev => {
//             return {
//                 ...prev,
//                 probationDate: value
//             }
//         })
//     }
//     const calculateProbationDate = ({ value, text }) => {
//         console.log(employeDetail.type_of_employee);
//         if (!value) return
//         if (employeDetail.type_of_employee === 'consultant') {
//             const currentDate = new Date(value);
//             const fortyFiveDaysFromToday = new Date(currentDate);
//             fortyFiveDaysFromToday.setDate(currentDate.getDate() + ON_SIDE);
//             setEmployeDetail(prev => {
//                 return {
//                     ...prev,
//                     probationDate: fortyFiveDaysFromToday
//                 }
//             })
//         } else {
//             if (text === 'Part Time') {
//                 const currentDate = new Date(value);
//                 const fortyFiveDaysFromToday = new Date(currentDate);
//                 fortyFiveDaysFromToday.setDate(currentDate.getDate() + PART_TIME_EMPLOYEE);
//                 setEmployeDetail(prev => {
//                     return {
//                         ...prev,
//                         probationDate: fortyFiveDaysFromToday
//                     }
//                 })

//             } else if (text === 'Full Time') {
//                 const currentDate = new Date(value);
//                 const fortyFiveDaysFromToday = new Date(currentDate);
//                 fortyFiveDaysFromToday.setDate(currentDate.getDate() + FULL_TIME_EMPLOYEE);
//                 setEmployeDetail(prev => {
//                     return {
//                         ...prev,
//                         probationDate: fortyFiveDaysFromToday
//                     }
//                 })
//             }
//         }
//     }
//     const [statutoryFormVisiblity, setStatutoryFormVisiblity] = useState({
//         isEpf: false,
//         isEsi: false,
//     })
//     const handleChangeStatutoryComponent = (e) => {
//         let checked = e.target.checked
//         setStatutoryFormVisiblity(prev => {
//             return {
//                 ...prev,
//                 [e.target.name]: checked
//             }
//         })
//         let data = employeDetail.statutory
//         if (e.target.name === 'isEpf') {
//             data['epf']['isEpf'] = checked
//         } else {
//             data['esi']['isEsi'] = checked
//         }
//         setEmployeDetail({ ...employeDetail })
//     }
//     const handleChangeStatutoryForm = (e, key) => {
//         let name = e.target.name
//         let value = e.target.value
//         let copyData = employeDetail
//         copyData.statutory[key][name] = value
//         setEmployeDetail({ ...employeDetail })
//     }
//     const handleChangeStatutoryCheckbox = (e, key) => {
//         let name = e.target.name
//         let value = e.target.checked
//         let copyData = employeDetail
//         if (key === 'isPf') {
//             copyData.statutory[key][name] = value
//         } else {
//             copyData.statutory[name] = value
//         }
//         setEmployeDetail({ ...employeDetail })
//     }
//     return (
//         <>
//             {isLoading ? <div className=' w-16 h-16 mx-auto my-16'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className=" bg-[color:var(--color3)]">
//                 <div className=" container">
//                     <div className=" px-4 py-4 text-start">
//                         <Link to={"/employee-details"} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Back</Link>
//                     </div>
//                     <form action="" method="POST">
//                         <div className=" ">
//                             <div className=" px-4">
//                                 <button type='button' onClick={() => setRegeneratePasswordModal(true)} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Regenerate Password</button>
//                             </div>
//                             <div className=" grid grid-cols-2 gap-4 p-4 border-b-2">
//                                 <input type="hidden" value={employeDetail.user_id} name='user_id' />
//                                 <div className="">
//                                     <label htmlFor="first_name" className='label'>First Name:</label>
//                                     <input type="text" name='first_name' required value={employeDetail.first_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter first name ' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="last_name" className='label'>Last Name:</label>
//                                     <input type="text" name='last_name' required value={employeDetail.last_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter last name ' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="address_1" className='label'>Address Line 1:</label>
//                                     <input type="text" name='address_1' className='input' value={employeDetail.address_1} onChange={handleSetEmployeDetail} placeholder='Enter address line 1 code' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="address_2" className='label'>Address Line 2:</label>
//                                     <input type="text" name='address_2' className='input' value={employeDetail.address_2} onChange={handleSetEmployeDetail} placeholder='Enter address line 2 code' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="pincode" className='label'>Pincode:</label>
//                                     <input type="text" name='pincode' className='input' value={employeDetail.pincode} onChange={handleSetEmployeDetail} placeholder='Pincode' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="city" className='label'>City:</label>
//                                     <input type="text" name='city' className='input' value={employeDetail.city} onChange={handleSetEmployeDetail} placeholder='Enter city ' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="email" className='label'>Email:</label>
//                                     <input type="email" name='email' className='input' required value={employeDetail.email} onChange={handleSetEmployeDetail} placeholder='Email' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="DOB" className='label'>Date Of Birth:</label>
//                                     <input type="date" name='DOB' className='input' value={employeDetail.DOB} onChange={handleSetEmployeDetail} placeholder='Enter DOB ' />
//                                 </div>
//                                 <div className="">
//                                     <p className='label'>Gender:</p>
//                                     <div className=" flex gap-x-4" >
//                                         <Radio label={"Male"} name={"gender"} value={"male"} val={employeDetail.gender} onChange={handleSetEmployeDetail} />
//                                         <Radio label={"Female"} name={"gender"} value={"female"} val={employeDetail.gender} onChange={handleSetEmployeDetail} />
//                                     </div>
//                                 </div>
//                                 <br />
//                                 <div className="">
//                                     <label htmlFor="mobile_number" className='label'>Mobile Number:</label>
//                                     <input type="number" name='mobile_number' className='input' value={employeDetail.mobile_number} onChange={handleSetEmployeDetail} placeholder='Enter mobile number' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="home_phone" className='label'>Alternate Number:</label>
//                                     <input type="text" name='home_phone' className='input' value={employeDetail.home_phone} onChange={handleSetEmployeDetail} placeholder='Alternate Number' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="bank_name" className='label'>Bank Name:</label>
//                                     <input type="text" name='bank_name' className='input' value={employeDetail.bank_name} onChange={handleSetEmployeDetail} placeholder='Bank name' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="ifsc_code" className='label'>IFSC Code:</label>
//                                     <input type="text" name='ifsc_code' className='input' value={employeDetail.ifsc_code} onChange={handleSetEmployeDetail} placeholder='IFSC Code' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="account_number" className='label'>Account Number:</label>
//                                     <input type="number" name='account_number' className='input' value={employeDetail.account_number} onChange={handleSetEmployeDetail} placeholder='Account number' />
//                                 </div>
//                                 {userType !== 'employee' && <div className="">
//                                     <label htmlFor="gst_no" className='label'>GST Number:</label>
//                                     <input type="text" name='gst_number' className='input' value={employeDetail.gst_number} onChange={handleSetEmployeDetail} placeholder='GST Number' />
//                                 </div>}
//                                 {/* <br /> */}
//                                 <div className="">
//                                     <label htmlFor="pan_number" className='label'>Pan Number:</label>
//                                     <input type="text" name='pan_number' className='input' value={employeDetail.pan_number} onChange={handleSetEmployeDetail} placeholder='Pan number' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="aadhar" className='label'>Aadhar Number:</label>
//                                     <input type="text" name='aadhar_number' className='input' value={employeDetail.aadhar_number} onChange={handleSetEmployeDetail} placeholder='Aadhar number' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="qualification" className='label'>Educational Qualification:</label>
//                                     <input type="text" name='qualification' className='input' value={employeDetail.qualification} onChange={(e) => setEducationFile(e)} placeholder='Enter your qualification' />
//                                 </div>
//                                 <div className=" invisible">
//                                     <label htmlFor="education_certificates" className='label'>Education Certificates:</label>
//                                     <input type="file" multiple name='education_certificates' className='input border-none' value={employeDetail.education_certificates} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="work_experience" className='label'>Work Experience :</label>
//                                     <input type="text" name='work_experience' className='input' value={employeDetail.work_experience} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
//                                 </div>
//                                 <div className=" invisible">
//                                     <label htmlFor="work_documents" className='label'>Work Experience Documents:</label>
//                                     <input type="file" multiple name='work_documents' className='input border-none' value={employeDetail.work_documents} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="designation" className='label'>Designation:</label>
//                                     <input type="text" name='designation' className='input' value={employeDetail.designation} onChange={handleSetEmployeDetail} placeholder='Designation' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="department" className='label'>Department:</label>
//                                     <input type="text" name='department' className='input' value={employeDetail.department} onChange={handleSetEmployeDetail} placeholder='Department' />
//                                 </div>

//                                 {/* <div className="">
//                                     <label htmlFor="date_of_resignation" className='label'>Date Of Resignation:</label>
//                                     <input type="date" name='date_of_resignation' className='input' value={employeDetail.date_of_resignation} onChange={handleSetEmployeDetail} placeholder='Date of resignation' />
//                                 </div>
//                                 <div className="">
//                                     <label htmlFor="date_of_leaving" className='label'>Date Of Leaving:</label>
//                                     <input type="date" name='date_of_leaving' className='input' value={employeDetail.date_of_leaving} onChange={handleSetEmployeDetail} placeholder='Date of leaving' />
//                                 </div> */}
//                             </div>
//                             <div className=' space-y-4 py-4'>
//                                 <div className='px-4 grid grid-cols-2 space-x-4'>
//                                     <div className="">
//                                         <label htmlFor="date_of_joining" className='label'>Date Of Joining:</label>
//                                         <input type="date" name='date_of_joining' className='input' value={employeDetail.date_of_joining} onChange={handleSetEmployeDetail} placeholder='Date of joining' />
//                                     </div>
//                                     <div className="">
//                                         <label htmlFor="date_of_leaving" className='label'>Probation date:</label>
//                                         <DatePicker name='probationDate' autoComplete='false' className='input' dateFormat="dd/MM/yyyy" selected={employeDetail?.probationDate ? new Date(employeDetail?.probationDate) : ''} onChange={(value) => handleDate(value, 'probationDate')} />
//                                         {/* <input type="date" name='date_of_joining' className='input' value={employeDetail.probationDate} onChange={handleSetEmployeDetail} placeholder='Date of joining' /> */}
//                                     </div>
//                                 </div>
//                             </div>
//                             {employeDetail.type_of_employee === 'employee' &&
//                                 <div className='px-5 mt-10'>
//                                     <div>
//                                         <h1 className='mt-4 text-2xl font-semibold'>Statutory Components</h1>
//                                         <small>Enable the necessary benefits and tax applicable for this employee.</small>
//                                     </div>
//                                     <div className=' space-y-4'>
//                                                     <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
//                                                         <div className=" mt-4">
//                                                             <div className=' space-x-2'>
//                                                                 <input type="checkbox" id='isEpf' checked={employeDetail.statutory.epf.isEpf} name='isEpf' onChange={handleChangeStatutoryComponent} />
//                                                                 <label htmlFor='isEpf' className=' cursor-pointer'>Employees' Provident Fund</label>
//                                                             </div>
//                                                             {employeDetail.statutory.epf.isEpf &&
//                                                                 <div className='border-b border-gray-200 pb-4'>
//                                                                     <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
//                                                                         <div className="">
//                                                                             <label htmlFor="total_leave" className='label'>PF Account Number:</label>
//                                                                             <input type="text" name='pf_account_number' value={employeDetail.statutory.epf.pf_account_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='AA/AAA/0000000/000/0000000' />
//                                                                         </div>
//                                                                         <div className="">
//                                                                             <label htmlFor="total_leave" className='label'>UAN:</label>
//                                                                             <input type="text" name='pf_uan_number' value={employeDetail.statutory.epf.pf_uan_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>}
//                                                         </div>
//                                                     </div>
//                                                     <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
//                                                         <div className=" mt-4">
//                                                             <label htmlFor="" className=' space-x-2 cursor-pointer'>
//                                                                 <input type="checkbox" checked={employeDetail?.statutory?.esi?.isEsi} name='isEsi' id='isEsi' onChange={handleChangeStatutoryComponent} />
//                                                                 <label htmlFor='isEsi' className=''>Employees' State Insurance</label>
//                                                             </label>
//                                                             {employeDetail?.statutory?.esi?.isEsi &&
//                                                                 <div className='border-b border-gray-200 pb-4'>
//                                                                     <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
//                                                                         <div className="">
//                                                                             <label htmlFor="total_leave" className='label'>ESI Insurance Number:</label>
//                                                                             <input type="text" name='esi_number' value={employeDetail.statutory.esi.esi_number} onChange={(e) => handleChangeStatutoryForm(e, 'esi')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
//                                                                         </div>
//                                                                     </div>
//                                                                     <label htmlFor="" className=' space-x-2 mt-4 block'>
//                                                                         <small className=' text-gray-500'>Note: ESI deductions will be made only if the employee’s monthly salary is less than or equal to ₹21,000 </small>
//                                                                     </label>
//                                                                 </div>}
//                                                         </div>
//                                                     </div>
//                                                     <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
//                                                         <div className=" mt-4">
//                                                             <label htmlFor="" className=' space-x-2 cursor-pointer'>
//                                                                 <input type="checkbox" name='isPt' id='isPt' checked={employeDetail.statutory.isPt} onChange={(e) => handleChangeStatutoryCheckbox(e, 'isPt')} />
//                                                                 <label htmlFor='isPt' className=''>Professional Tax</label>
//                                                             </label>
//                                                             <label htmlFor="" className=' space-x-2 block'>
//                                                                 <small className=' text-gray-500'>
//                                                                     Note: Professional Tax deductions <br />
//                                                                     Male employee if salary between &#8377; 7,500 - &#8377; 10,000
//                                                                     - (&#8377;175) will be deducted if salary greater then &#8377; 10,000 - (&#8377;200) will be deducted <br />
//                                                                     Female employee if salary between &#8377; 7,500 - &#8377; 25,000
//                                                                     - (&#8377;0) will be deducted if salary greater then &#8377; 25,001 - (&#8377;200) will be deducted <br />
//                                                                 </small>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                 </div>}
//                         </div>
//                         <div className=" text-center my-8 space-x-4">
//                             <button type='button' onClick={handleSubmitEmployeDetail} className=' bg-[color:var(--color1)] hover:bg-[color:var(--color9)] text-white py-3 px-20 rounded-md'>Submit</button>
//                             <Link to={`/resign-employee/${id}/${employeDetail.employee_name}/${employeDetail.type_of_employee}`} className=' bg-red-600 hover:bg-[color:var(--color9)] text-white py-3 px-20 rounded-md'>Resignation/Termination</Link>
//                         </div>
//                     </form>
//                 </div>
//             </div >}
//             <Modals show={isShowingModal}>
//                 <div>
//                     <p className=' roboto'>Your Data is Sucessfully Stored </p>
//                     <div className=" mt-12">
//                         <Link to={"/employee-details"} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</Link>
//                     </div>
//                 </div>
//             </Modals>
//             <Modal open={regeneratePasswordModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
//                 <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
//                     <div className='document-wrapper px-4 divide-y'>
//                         <div className="document-head py-4">
//                             <div className='flex items-center justify-between'>
//                                 <h3 className=' text-gray-700 text-3xl font-semibold'>Reset Password</h3>
//                                 <div className=' w-6 h-6 cursor-pointer' onClick={() => setRegeneratePasswordModal(false)}>
//                                     <XIcon className=' text-black w-full h-full' />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="document-body py-4 max-h-96 overflow-auto">
//                             <form onSubmit={handleSetNewPassword}>
//                                 <div className=' grid grid-cols-2 gap-4 '>
//                                     <div className="">
//                                         <label htmlFor="gross_salary" className='label'>Password</label>
//                                         <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={newPassword} />
//                                     </div>
//                                     <div className="">
//                                         <label htmlFor="gross_salary" className='label'></label>
//                                         {newPassword ? ' ' : <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Generate Password</button>}
//                                     </div>
//                                 </div>
//                                 {isResettingPassword ? <div className='w-6 h-6 mx-0 mt-5'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : newPassword && <div className='mt-5'>
//                                     <button className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Save</button>
//                                 </div>}
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </Modal>
//             <EmployeePromotionModal open={isOpenEmployeePromotionModal} onClose={() => setIsOpenEmployeeModal(false)} />
//         </ >
//     )
// }

// export default AddEmployeeDetails
import React, { useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import {
  AddressDetailsForm,
  PersonalDetailsForm,
  BankDetailsForm,
  WorkDetailsForm,
} from "./EmployeeForm";
import useRead from "../../hooks/useRead";
import Spinner from "../../components/loader/Spinner";
import EmployeeProvider from "../../providers/EmployeeProvider";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useGet from "../../hooks/read/useGet";
import EditEmployeeProvider from "../../providers/EditEmployeeProvider";
function EditEmployee() {
    
    const [editData,setEditData] = useState({})
    const { id, username } = useParams() 
    const { list  } = useGet({
        url: 'employeedetails_edit',
        initialData: { id },
        onSuccess: (res) => {
            setEditData(res?.data)
        },
        onError:(err) => {
            const {status,data} = err.response || {}
            if(status >= 400 || status <=499){
                toast.error(data.message)
            }else{
                toast.error('Internal Server Error')
            }
        }
    })
  const [step, setStep] = useState(0);
  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };
  const handlePrevStep = () => {
    setStep((prev) => prev - 1);
  };
  const { list: employmentList } = useRead({
    url: "employment_listing",
    initialData: {
      limit: 50,
      search_query: "",
    },
  });
  const { list: shiftList } = useRead({
    url: "shift_listing",
    method:'GET',
    initialData: {
      limit: 50,
      search_query: "",
    },
  });

  const { list: departmentListing } = useRead({
    url: "department_listing",
    method:'GET',
    initialData: {
      limit: 50,
      search_query: "",
    },
  });

  return (
    <EditEmployeeProvider initialValue={editData}>
      <Toaster/>
      {(employmentList.isLoading || shiftList.isLoading || departmentListing.isLoading) && <Spinner />}
      <div className="px-5">
        <Stepper
          steps={[
            { label: "Personal Details" },
            { label: "Address Details" },
            { label: "Work Details" },
            { label: "Bank Details" },
          ]}
          activeStep={step}
        />
        <div className="my-10">
          {step === 0 && (
            <>
              <PersonalDetailsForm   
                onNextStep={handleNextStep}
                employeeId={editData.id}
                // prefilledData={employeeDetails}
              />
              
            </>
          )}
          {step === 1 && (
            <AddressDetailsForm
              onNextStep={handleNextStep}
              onPreviousStep={handlePrevStep}
            />
          )}
          {step === 2 && (
            <WorkDetailsForm
              onNextStep={handleNextStep}
              onPreviousStep={handlePrevStep}
              employmentList={employmentList.data?.data || []}
              shiftList={shiftList.data?.data || []}
              departmentListing={departmentListing?.data?.data || []}
            />
          )}
          {step === 3 && (
            <BankDetailsForm
              onNextStep={handleNextStep}
              onPreviousStep={handlePrevStep}
            />
          )}
        </div>
      </div>
    </EditEmployeeProvider>
  );
}

export default EditEmployee;
