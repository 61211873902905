import React, { useEffect, useState } from 'react'
import { ChevronLeftIcon, EyeIcon } from '@heroicons/react/solid'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AdvanceEmploymentEditing from './AdvanceEmploymentEditing'
import PrimaryLoader from '../../components/common/PrimaryLoader'
import toast, { Toaster } from 'react-hot-toast'
import useGet from '../../hooks/read/useGet'
import Spinner from '../../components/loader/Spinner'
// import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Button } from '../../components/ui'
import Moment from 'react-moment'

const SingleEmployeeDetail = () => {
    const constructstringbasedonyears = (year) => {
        return +year >= 1 ? year + ' year' : year + ' years'
    }
    const [userData, setUserData] = useState(null);

    const [isOpenAdvanceEmployment, setIsOpenAdvanceEmployment] = useState(false)
    const { id, username } = useParams()
    const navigate = useNavigate()
    const { list } = useGet({
        url: 'employeedetails_edit',
        initialData: { id },
        onError: (err) => {
            const { status, data } = err.response || {}
            if (status >= 400 || status <= 499) {
                toast.error(data.message)
            } else {
                toast.error('Internal Server Error')
            }
        }
    })

    const handleOpenAdvanceMapping = () => {
        setIsOpenAdvanceEmployment(true)
    }
    let type = list?.data?.data.type_of_employee
    const { personalDetail, bankDetail, addressDetail, workDetail, statutory } = list.data?.data || {}
    return (
        <>
            <Toaster />
            {list.isLoading
                ? <Spinner />
                :
                <>
                    <div className='px-4 pb-1 w-full shadow-sm font-bold'>Details for "{personalDetail.firstName + " " + personalDetail.lastName}" </div>
                    <div className=' px-4 pb-4 flex justify-end sticky bg-white shadow-lg'>
                        <div className=' space-x-5'>
                            <button onClick={handleOpenAdvanceMapping} className='px-5 py-2 bg-orange-300 rounded font-semibold text-sm'>Assign New Shift</button>
                            {/* <Link to={`/employee-promotion/${id}/${username}`} className='px-5 py-2 bg-blue-300 rounded font-semibold text-sm'>Promote/Reassign</Link> */}
                            <Link to={`/employee-salary-details/${id}/${username}`} className='px-5 py-2 bg-yellow-300 rounded font-semibold text-sm'>Salary Details</Link>
                            <Link to={`/resign-employee/${id}/${username}`} className='px-5 py-2 bg-red-300 rounded font-semibold text-sm'>Resign Employee</Link>
                            <Link to={`/loan/${id}/${username}`} className='px-5 py-2 bg-teal-300 rounded font-semibold text-sm'>Loan</Link>
                        </div>
                    </div>
                    <div className='px-4 space-y-4 my-5'>
                        <div>
                            <div className='grid grid-cols-2 gap-5'>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'> {type} Personal Details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>First Name</td>
                                            <td className='text-sm border py-3 px-4'>{personalDetail.firstName || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Last Name</td>
                                            <td className='text-sm border py-3 px-4'>{personalDetail.lastName || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Email</td>
                                            <td className='text-sm border py-3 px-4'>{personalDetail.email || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Date Of Birth</td>
                                            <td className='text-sm border py-3 px-4'>
                                                {/* <Moment unix format='DD/MM/YYYY'date= {personalDetail.dateOfBirth || '----'}/> */}
                                                {personalDetail.dateOfBirth
                                                    ? <Moment unix format='DD/MM/YYYY' date={personalDetail.dateOfBirth} />
                                                    : '----'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Gender</td>
                                            <td className='text-sm border py-3 px-4 capitalize'>{personalDetail.gender || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Mobile Number</td>
                                            <td className='text-sm border py-3 px-4'>{personalDetail.mobileNumber || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Alternate Number</td>
                                            <td className='text-sm border py-3 px-4'>{personalDetail.alternateMobileNumber || '----'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type}Permanent Address Details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Address Line 1</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.permanentAddress.addressLine1 || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Address Line 2</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.permanentAddress.addressLine2 || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Pincode</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.permanentAddress.pincode || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>City</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.permanentAddress.city || '----'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type}Current Address Details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Address Line 1</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.currentAddress.addressLine1 || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Address Line 2</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.currentAddress.addressLine2 || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Pincode</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.currentAddress.pincode || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>City</td>
                                            <td className='text-sm border py-3 px-4'>{addressDetail.currentAddress.city || '----'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Bank Details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Bank Name</td>
                                            <td className='text-sm border py-3 px-4'>{bankDetail.bankName || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Account Number</td>
                                            <td className='text-sm border py-3 px-4'>{bankDetail.accountNumber || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>IFSC Code</td>
                                            <td className='text-sm border py-3 px-4'>{bankDetail.ifsc || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Pan Number</td>
                                            <td className='text-sm border py-3 px-4'>{bankDetail.panNumber || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Aadhar Number</td>
                                            <td className='text-sm border py-3 px-4'>{bankDetail.adhaarNumber || '----'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Previous Work Details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Educational Qualification</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.previousWorkDetail.education || '----'}</td>
                                        </tr>

                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Work Experience</td>
                                            <td className='text-sm border py-3 px-4'>{constructstringbasedonyears(+workDetail.previousWorkDetail.workExperience)}</td>
                                        </tr>


                                    </tbody>
                                </table>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Current Work Details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Designation</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.currentWorkDetail.designation || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Date of Joining</td>
                                            <td className='text-sm border py-3 px-4'>
                                                {/* {workDetail.currentWorkDetail.dateOfJoining || '----'} */}
                                                {workDetail.currentWorkDetail.dateOfJoining
                                                    ? <Moment unix format='DD/MM/YYYY' date={workDetail.currentWorkDetail.dateOfJoining} />
                                                    : '----'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Department</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.currentWorkDetail.departmentName || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Employee type</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.currentWorkDetail.employmentTypeName || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Probation Date</td>
                                            <td className='text-sm border py-3 px-4'>
                                                {/* {workDetail.currentWorkDetail.probationDate || '----'} */}
                                                {workDetail.currentWorkDetail.probationDate
                                                    ? <Moment unix format='DD/MM/YYYY' date={workDetail.currentWorkDetail.probationDate} />
                                                    : '----'}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Shift</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.currentWorkDetail.shift || '----'}</td>
                                        </tr> */}



                                    </tbody>
                                </table>
                                <table className='w-full'>
                                    <thead>
                                        <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Statutory Components</th></tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Employees' Provident Fund</td>
                                        <td className='text-sm border py-3 px-4'></td>
                                    </tr> */}
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>PF Number</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.statutory.employeeProvidentFund.providentFundNumber || '----'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>UAN</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.statutory.employeeProvidentFund.UAN === '1' ? 'Applicable' : 'Not Applicable'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Professional Tax</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.statutory.employeeStateInsurance.professionalTax === '1' ? 'Applicable' : 'Not Applicable'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>Employees' State Insurance</td>
                                            <td className='text-sm border py-3 px-4'>{list?.data?.data.esic === '1' ? 'Applicable' : 'Not Applicable'}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 text-xs'>ESIC Number</td>
                                            <td className='text-sm border py-3 px-4'>{workDetail.statutory.employeeStateInsurance.insuranceNumber || '----'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>

                        </div>

                        {/* <div>
                        <div className='grid grid-cols-2 gap-5'>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Salary Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Gross Salary</td>
                                        <td className='border py-3 px-4'>Employee Name</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Professional Tax</td>
                                        <td className='border py-3 px-4'>Designation</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Net Salary</td>
                                        <td className='border py-3 px-4'>Department</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Total Leave</td>
                                        <td className='border py-3 px-4'>listData?.date_of_joining</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                    </div>
                </>
            }
            <AdvanceEmploymentEditing onClose={() => setIsOpenAdvanceEmployment(false)} open={isOpenAdvanceEmployment} />
        </>
    )
}

export default SingleEmployeeDetail