import useGet from '../read/useGet'

const useGetSingleLoadDetail = ({
    id
}) => {
    const initialState = {
        id
    }
    const { list: responseData } = useGet({
        url: 'fetch_single_employee_loan',
        initialData: initialState
    })
    return { responseData }
}

export default useGetSingleLoadDetail