import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useRead from '../../../hooks/useRead'
import { numberWithComma } from '../../../utility'
import PrimaryLoader from '../../../components/common/PrimaryLoader'
import toast, { Toaster } from 'react-hot-toast'


const EmployeeCTCDetail = ({fetchEmployeeType}) => {
    const { id, username } = useParams()
    const navigate = useNavigate()
    const { list } = useRead({
        url: 'getEmployeeCurrentSalary',
        initialData: {
            employee_id: id
        },
        onSuccess: (res) => {
            // toast.success('Data Fetched Successfully');
            fetchEmployeeType(res.type_of_employee)
        },
        onError: () => {
            toast.error('There Was An Error While Fetching Data Please Try Again Later');
        }
    })
    const handleNavigateToEmployeeDetail = () => {
        navigate(`/employee-salary/${id}/${username}?employeeType=${list.data?.type_of_employee}`, {
            state: list.data?.data[0]
        })
    }
    return (
        <>
            {list.isLoading &&
                <div className=' fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-[#ffffffc7]'>
                    <PrimaryLoader />
                </div>}
            <div className=' max-w-4xl px-8 py-8'>
                <Toaster />
                <div>
                    <div className='flex items-center space-x-3'>
                        <h1 className='font-semibold text-xl'>Salary Detail</h1>
                        {list.data?.data.length > 0 ? 
                        <button className=' bg-color1 text-white px-6 text-xs py-2 rounded-md' onClick={handleNavigateToEmployeeDetail} >Edit</button> : 
                        <Link to={`/employee-salary/${id}/${username}?employeeType=${list.data?.type_of_employee}`} className=' bg-color1 text-white px-6 text-xs py-2 rounded-md'>Add</Link>
                         }
                    </div>
                    <div className='flex items-center space-x-16 mt-5'>
                        <div className=' space-y-2'>
                            <p className='text-gray-500 text-sm'>ANNUAL CTC</p>
                            <h2 className='text-gray-800 font-bold text-lg'>₹ {numberWithComma(list.data?.data[0]?.annual_ctc)} per year</h2>
                        </div>
                        <div className=' space-y-2'>
                            <p className='text-gray-500 text-sm'>MONTHLY CTC</p>
                            <h2 className='text-gray-800 font-bold text-lg'>₹{numberWithComma(list.data?.data[0]?.monthly_ctc)} per month</h2>
                        </div>
                    </div>
                </div>

                <div className='border border-gray-200 rounded shadow-lg mt-10 px-5'>
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="text-gray-600 border-b border-gray-300">
                                <th className="w-1/6 min-w-[160px] text-xs font-semibold py-4 lg:py-4 px-3 lg:px-4 text-left">SALARY COMPONENTS</th>
                                <th className="w-1/6 min-w-[160px] text-xs font-semibold py-4 lg:py-4 px-3 lg:px-4 text-right">MONTHLY AMOUNT</th>
                                <th className="w-1/6 min-w-[160px] text-xs font-semibold py-4 lg:py-4 px-3 lg:px-4 text-right">ANNUAL AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">Earnings</td>
                                <td className=""></td>
                                <td className=""></td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>
                                        Basic
                                    </span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.basic_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.basic_yearly)}</p>
                                </td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>DA</span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.da_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.da_yearly)}</p>
                                </td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>Conveyance</span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.conveyance_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.conveyance_yearly)}</p>
                                </td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>HRA</span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.hra_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.hra_yearly)}</p>
                                </td>
                            </tr>
                            {/* <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>Medical</span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.medical_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.medical_yearly)}</p>
                                </td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>Others</span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.other_allowance_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.other_allowance_yearly)}</p>
                                </td>
                            </tr> */}
                        </tbody>
                        {/* <tbody className=' border-t border-gray-300'>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">Deductions</td>
                                <td className=""></td>
                                <td className=""></td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>ESI - Employer Contribution</span><br />
                                    <span className='text-sm text-gray-500'>(3.25% of ESI Wages)</span>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.esic_monthly)}</p>
                                </td>
                                <td className="text-right">
                                    <p>₹ {numberWithComma(list.data?.data[0]?.esic_yearly)}</p>
                                </td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>EPF Deduction</span><br />
                                    <span className='text-sm text-gray-500'>(12.00% of PF Wages)</span>
                                </td>
                                <td className="text-right">
                                    <p>---</p>
                                </td>
                                <td className="text-right">
                                    <p>---</p>
                                </td>
                            </tr>
                        </tbody> */}
                        <tfoot>
                            <tr className=' border-t border-gray-300'>
                                <td className="px-3 font-semibold py-4">Cost to Company</td>
                                <td className="">
                                    <p className=' text-right font-semibold text-xl'>₹ {numberWithComma(list.data?.data[0]?.monthly_ctc)}</p>
                                </td>
                                <td className="">
                                    <p className=' text-right font-semibold text-xl'>₹ {numberWithComma(list.data?.data[0]?.annual_ctc)}</p>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                {/* <div className='border border-gray-200 rounded shadow-lg mt-10 px-5'>
                    <h2 className='px-3 font-semibold py-4'>Others</h2>
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="text-gray-600 border-b border-gray-300">
                                <th className="w-1/6 min-w-[160px] text-xs font-semibold py-4 lg:py-4 px-3 lg:px-4 text-left">DEDUCTION NAME</th>
                                <th className="w-1/6 min-w-[160px] text-xs font-semibold py-4 lg:py-4 px-3 lg:px-4 text-right">CALCULATION TYPE</th>
                                <th className="w-1/6 min-w-[160px] text-xs font-semibold py-4 lg:py-4 px-3 lg:px-4 text-right">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className=''>
                                <td className="text-sm font-semibold py-4 px-3">Pre-Tax Deductions</td>
                                <td className="text-right"><p>Applicable</p></td>
                                <td className="text-right"><p>---</p></td>
                            </tr>
                            <tr className=''>
                                <td className="px-3 font-semibold py-4">
                                    <span className='text-sm font-semibold'>EPF Contribution</span><br />
                                </td>
                                <td className="text-right">
                                    <p>12.00% of PF Wages</p>
                                </td>
                                <td className="text-right">
                                    <p>---</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
            </div>
        </>
    )
}

export default EmployeeCTCDetail