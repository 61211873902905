import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../ui'
import useMutatePettyCash from '../../../hooks/PettyCash/useMutatePettyCash'
import { RxReload } from 'react-icons/rx'
import useGetOpeningBalance from '../../../hooks/PettyCash/useGetOpeningBalance'

const PettyCashForm = ({
    formData,
    mode
}) => {
    const [isOpeningBalanceFetched, setOpenBalanceFetched] = useState(false)
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            name: '',
            site_name: '',
            voucher_date: '',
            chq_no: '',
            chq_date: '',
            chq_amount: '',
            opening_balance: '',
            other_income: '',
            description: ''
        }
    })
    const { handleSubmitForm: handleGetOpeningBalance } = useGetOpeningBalance({
        onSuccess: (res) => {
            setValue('opening_balance', res.data.opening_balance)
            setOpenBalanceFetched(true)
        }
    })
    const { mutatePettyCash, handleSubmitForm } = useMutatePettyCash()
    useEffect(() => {
        if (formData) {
            Object.keys(formData).forEach((key) => {
                setValue(key,formData[key])
            })
        }
    }, [formData])
    return (
        <>
            <div className=' space-y-4'>
                <div className='grid grid-cols-3 items-end gap-4 pb-10'>
                    <div>
                        <label className='label'>Voucher Date: </label>
                        <input placeholder='' className='input' type='date' {...register("voucher_date", { required: true })} />
                    </div>
                    <div>
                        <button onClick={handleSubmit(handleGetOpeningBalance)} className=' bg-yellow-400 px-5 py-2 rounded-md'>Get Opening Balance</button>
                    </div>
                </div>
                {(isOpeningBalanceFetched || mode === 'edit') &&
                    <>
                        <div className='grid grid-cols-3 gap-4'>
                            <div>
                                <label className='label'>Name: </label>
                                <input placeholder='' className='input' {...register("name", { required: true })} />
                            </div>
                            <div>
                                <label className='label'>Site name: </label>
                                <input placeholder='' className='input' {...register("site_name", { required: true })} />
                            </div>
                            <div></div>
                            <div>
                                <label className='label'>Cheque Number: </label>
                                <input placeholder='' className='input' {...register("chq_no", { required: true })} />
                            </div>
                            <div>
                                <label className='label'>Cheque Amount: </label>
                                <input placeholder='' className='input' {...register("chq_amount", { required: true })} />
                            </div>
                            <div>
                                <label className='label'>Cheque Date: </label>
                                <input placeholder='' className='input' type='date' {...register("chq_date", { required: true })} />
                            </div>
                            <div>
                                <label className='label'>Opening Balance: </label>
                                <input placeholder='' className='input' {...register("opening_balance", { required: true })} />
                            </div>
                            <div className=''>
                                <label className='label'>Other Income: </label>
                                <input placeholder='' className='input' {...register("other_income", { required: true })} />
                            </div>
                            <div className=''>
                            </div>
                            <div className=' col-span-2'>
                                <label className='label'>Remark: </label>
                                <textarea {...register("description", { required: true })} className='input min-h-[10rem]' />
                            </div>
                        </div>
                        <div className=' text-center mt-10'>
                            <Button disabled={mutatePettyCash.isLoading} className={'px-4 py-2'} onClick={handleSubmit(handleSubmitForm)}>
                                Save Voucher
                                {mutatePettyCash.isLoading && <RxReload className=' ml-2 w-4 h-4' />}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default PettyCashForm


