import { Controller } from "react-hook-form";
const Checkbox = ({
  className,
  type,
  label,
  control,
  rules,
  name,
  onChangeFn,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <label className={" cursor-pointer " + className}>
            <input
              type="checkbox"
              checked={value}
              onChange={(e) => {
                onChange(e);
                onChangeFn(e);
              }}
              className="mr-2"
              {...props}
            />
            {label}
          </label>
          {error && (
            <small className="text-red-600 font-medium text-sm">
              {error.message}
            </small>
          )}
        </>
      )}
    />
  );
};

export default Checkbox;
