import React, { useState } from 'react'
import Pagination from '../../components/pagination'
import usePettyCashListing from '../../hooks/PettyCash/usePettyCashListing'
import { Button } from '../../components/ui'
import PettyCashCreate from './PettyCashCreate';
import PettyCashEdit from './PettyCashEdit';
import { EyeIcon, TrashIcon, PlusIcon } from "@heroicons/react/solid";
import { Toaster } from 'react-hot-toast';
import PettyCashDelete from './PettyCashDelete';
import { Link } from 'react-router-dom';


const PettyCashListing = () => {
    const [addDrawerOpen, setAddDrawerOpen] = useState(false)
    const [editDrawerOpen, setEditDrawerOpen] = useState(false)
    const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false)
    const [id, setId] = useState('')
    const { responseData, paramsObject, handlePageClick, handleSearch } = usePettyCashListing()
    const handleOpenCreateDrawer = () => {
        setAddDrawerOpen(true)
    }
    const handleOpenEdit = (id) => {
        setId(id)
        setEditDrawerOpen(true)
    }
    const handleOpenDelete = (id) => {
        setId(id)
        setDeleteDrawerOpen(true)
    }
    return (
        <>
            <Toaster />
            <>
                <div className="flex items-center justify-between px-4 mb-10">
                    <div className=' w-[400px]'>
                        <input type="search" placeholder='Search By Name...' name='search_query' className='input' value={paramsObject.search_query} onChange={handleSearch} />
                    </div>
                    <div>
                        <Link to={`/add-petty-cash`}>
                            <Button onClick={handleOpenCreateDrawer} className={'px-4 py-2'}>
                                <PlusIcon className='w-4 h-4 mr-2' />
                                Add Voucher
                            </Button>
                        </Link>
                    </div>
                </div>
            </>
            <div class="max-w-full overflow-x-auto px-4">
                <table class="table-auto w-full">
                    <thead>
                        <tr class=" bg-[color:var(--color1)] text-center">
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Site Name</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Opening Balance</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Closing Balance</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responseData?.data?.data.length <= 0
                            ? <tr>
                                <td colSpan={5}>
                                    <div className='text-center py-2 text-gray-600'>No Records Found</div>
                                </td>
                            </tr>
                            : <>
                                {
                                    responseData?.data?.data?.map((data, index) => {
                                        const { opening_balance, name, site_name, closing_balance, id } = data
                                        return <tr key={index}>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{name}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{site_name}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{opening_balance}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{closing_balance}</td>
                                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                                <div className='flex items-center justify-center w-full space-x-3'>
                                                    {/* <div onClick={() => handleOpenEdit(id)}> */}
                                                        <Link to={`/edit-petty-cash/${id}`}>
                                                            <EyeIcon className='h-6 text-gray-600 w-6 hover:text-orange-600' />
                                                        </Link>
                                                    {/* </div> */}
                                                    <div onClick={() => handleOpenDelete(id)}>
                                                        <TrashIcon className=' text-gray-600 w-6 h-6 hover:text-orange-600 cursor-pointer' />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </>}
                    </tbody>
                </table>
                <div className='px-5 mt-5 mb-10'>
                    <Pagination
                        currentPage={+paramsObject.page_number}
                        lengthofItems={responseData.data?.total_count}
                        limit={+paramsObject.limit}
                        onPageChange={handlePageClick}
                    />
                </div>
            </div>
            {/* <PettyCashCreate open={addDrawerOpen} handleCloseDialog={() => setAddDrawerOpen(false)} />
            {editDrawerOpen && <PettyCashEdit id={id} open={editDrawerOpen} handleCloseDialog={() => setEditDrawerOpen(false)} />}
            {deleteDrawerOpen && <PettyCashDelete id={id} open={deleteDrawerOpen} onClose={() => setDeleteDrawerOpen(false)}/>} */}
        </>
    )
}

export default PettyCashListing