import { XIcon } from "@heroicons/react/solid";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import { generatePasswordNew } from "../../../utility";
import useResetPassword from "../../../hooks/auth/useResetPassword";
import toast from "react-hot-toast";
import spinner from '../../../assets/images/spinner.gif'

const ResetPasswordModal = ({ open, onClose, userId }) => {
  const [newPassword, setNewPassword] = useState();

  const { reqObject, resetPassword } = useResetPassword({
    onSuccess: () => {
      onClose();
      toast.success("Password reset successfully");
    },
  });

  const handleGeneratePassword = () => {
    const options = {
      lowercase: true,
      uppercase: true,
      digits: true,
      specialChars: true,
    };
    const password = generatePasswordNew(16, options);

    setNewPassword(password);
  };

  const savePassword = (e) => {
    e.preventDefault();
    
    resetPassword({
      id: userId,
      password: newPassword,
    });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <div className="w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]">
        <div className="document-wrapper px-4 divide-y">
          <div className="document-head py-4">
            <div className="flex items-center justify-between">
              <h3 className=" text-gray-700 text-3xl font-semibold">
                Reset Password
              </h3>
              <div
                className=" w-6 h-6 cursor-pointer"
                onClick={() => onClose()}
              >
                <XIcon className=" text-black w-full h-full" />
              </div>
            </div>
          </div>
          <div className="document-body py-4 max-h-96 overflow-auto">
            <form onSubmit={savePassword}>
              <div className=" grid grid-cols-2 gap-4 ">
                <div className="">
                  <label htmlFor="gross_salary" className="label">
                    Password
                  </label>
                  <input
                    type="text"
                    name="password"
                    // readOnly
                    // disabled
                    className="input disabled:cursor-not-allowed"
                    placeholder="Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="">
                  <label htmlFor="gross_salary" className="label"></label>
                  {/* {"newPassword" ? (
                    " "
                  ) : ( */}
                  <button
                    type="button"
                    onClick={handleGeneratePassword}
                    className="px-5 py-2 rounded bg-gray-500 mt-9 text-white"
                  >
                    Generate Password
                  </button>
                  {/* )} */}
                </div>
              </div>
              {reqObject.isLoading ? (
                <div className="w-6 h-6 mx-0 mt-5">
                  <img src={spinner} alt="spinner" className="w-full h-full" />
                </div>
              ) : (
                newPassword && (
                  <div className="mt-5">
                    <button className=" bg-primary text-white rounded px-4 py-2">
                      Save
                    </button>
                  </div>
                )
              )}
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
