import React, { useState } from 'react'
import Modals from '../../../components/modal/Modals'
import useDeleteLoan from '../../../hooks/Loan/useDeleteLoan'
import { useParams } from 'react-router-dom'

const LoanDelete = () => {
    const { loanId } = useParams()
    const [isModalOpen,setIsModalOpen] = useState(false)
    const {handleDelete,mutateLoan} = useDeleteLoan({
        onSuccess: () => {
            setIsModalOpen(false)
        }
    })
    return (
        <>
            <button onClick={() => setIsModalOpen(true)} className=' text-red-600 font-semibold'>Delete Record</button>
            <Modals show={isModalOpen}>
                <div>
                    <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete?</p>
                    <div className=" mt-12 space-x-3 text-center ">
                        <button disabled={mutateLoan.isLoading} className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={() => handleDelete({id:loanId})}>Yes</button>
                        <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setIsModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            </Modals>
        </>
    )
}

export default LoanDelete