import { ABOVE_LIMIT_TAX, PERCENTILE, TAX, TDS } from "../config";
import moment from 'moment';

let characters = '';
let passwordLength = 10;
let grossSalary = 0
characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()<>,.?/[]{}-=_+|/0123456789';


export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
export const MOBILE_REGEX = /^[0-9]{10}$/;
export const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const ADHAR_REGEX = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;


export const taxCalculation = (employeeData) => {
    const { gst, gender, salary, tds, type_of_employee } = employeeData
    if (type_of_employee === "consultant") {
        if (salary === ' ' || !salary || salary <= 1) return { ...employeeData, gross_salary: 0, tds: 0, gst: 0, }
        return { ...employeeData, gross_salary: salary - gst - (TDS / PERCENTILE) * salary, tds: (TDS / PERCENTILE) * salary }
    } else if (type_of_employee === "employee") {
        if (salary === ' ' || !salary || salary <= 1) return { ...employeeData, gross_salary: 0, professional_tax: 0 }
        if (gender === "male") {
            if (salary <= 7500) return { ...employeeData, professional_tax: 0, gross_salary: salary }
            if (salary >= 7501 && salary <= 10000) return { ...employeeData, professional_tax: TAX, gross_salary: salary - TAX - tds }
            if (salary >= 10001) return { ...employeeData, professional_tax: ABOVE_LIMIT_TAX, gross_salary: salary - ABOVE_LIMIT_TAX - tds }
            return { ...employeeData, professional_tax: TAX, gross_salary: grossSalary }
        } else if (gender === "female") {
            if (salary <= 10000) return { ...employeeData, professional_tax: 0, gross_salary: salary }
            grossSalary = salary
            if (salary >= 10001) return { ...employeeData, professional_tax: ABOVE_LIMIT_TAX, gross_salary: salary - ABOVE_LIMIT_TAX - tds }
        }
    }
    return { ...employeeData }
}


export const generatePasswordNew = (length = 12, options = {}) => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';
    
    // Merge all character types based on options
    let allChars = '';
    if (options.lowercase !== false) allChars += lowercase;
    if (options.uppercase !== false) allChars += uppercase;
    if (options.digits !== false) allChars += digits;
    if (options.specialChars !== false) allChars += specialChars;
    
    if (allChars.length === 0) {
        throw new Error('No character types selected.');
    }

    let password = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        password += allChars[randomIndex];
    }
    
    return password;
}


export const handleCalculateEmi = (amount,months) => {
    if(!amount || !months) return null
    return +(+amount/+months).toFixed(2)
}




export const generatePassword = (passwordProps) => {
    const { uppercase, lowercase, symbols, numbers } = passwordProps;
    setPasswordLength(passwordLength);
    setUpperCase(uppercase);
    setLowerCase(lowercase);
    setSymbols(symbols);
    setNumber(numbers);
    const password = passwordCharacters();
    return password;
}

const setUpperCase = isUpperCase => {
    if (isUpperCase) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    return '';
}

const setLowerCase = isLowerCase => {
    if (isLowerCase) {
        characters += 'abcdefghijklmnopqrstuvwxyz';
    }
    return '';
}

const setSymbols = isSymbol => {
    if (isSymbol) {
        characters += '!@#$%^&*()<>,.?/[]{}-=_+|/';
    }
    return '';
}

const setNumber = isNumeric => {
    if (isNumeric) {
        characters += '0123456789';
    }
    return '';
}

const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const passwordCharacters = () => {
    let password = '';
    if (characters.length) {
        for (let i = 0; i < passwordLength; i++) {
            password += characters[getRandomInteger(0, characters.length - 1)];
        }
        characters = '';
        passwordLength = 0;
        return password;
    }
}

export const setPasswordLength = length => {
    passwordLength = length;
    return passwordLength;
}

export const formatDateForMonth = (day, dateObject) => {
    let month;
    if (dateObject.getMonth() + 1 < 10) {
        month = '0' + (dateObject.getMonth() + 1);
    }
    else {
        month = dateObject.getMonth() + 1;
    }
    if (day < 10) {
        return '0' + day + "-" + (month) + "-" + (dateObject.getUTCFullYear())
    }
    else {
        return day + "-" + (month) + "-" + (dateObject.getUTCFullYear())
    }
}

export const formatTime = (timeString) => {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}


export const handleTotalLeaveCalculation = (from, to) => {
    let totalLeave = 0
    totalLeave = from ? moment(to).diff(from, 'days') + 1 : 0
    return totalLeave
}

// Function returns "," for number values
export const numberWithComma = (value) => {
    if (!value) return 0
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const generateURL = (obj) => {
    // Initialize an array to hold key-value pairs
    const keyValuePairs = [];

    // Loop through the object's properties
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Encode the key and value and add them to the array
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(obj[key]);
            keyValuePairs.push(`${encodedKey}=${encodedValue}`);
        }
    }

    // Join the key-value pairs with "&" and add them to the base URL
    const queryString = keyValuePairs.join('&');
    const url = `?${queryString}`;

    return url;
}



export function calculateFractionOfFinancialYear(joiningDate) {
    // Get the current date
    const current = new Date()
    const currentYear = current.getFullYear()
    const currentDate = new Date(joiningDate);
    // console.log(currentDate.getFullYear() + 1);
    // Set the target date to March 31st of the same year
    let targetDate;
    if(currentYear === currentDate.getFullYear()){
        targetDate = new Date((currentDate.getFullYear() + 1), 2, 31); // March is month 2 (0-indexed)
    }else{
        targetDate = new Date(currentDate.getFullYear(), 2, 31); // March is month 2 (0-indexed)
    }

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - currentDate;

    // Calculate the number of days from milliseconds
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference === NaN ? '' : daysDifference
}