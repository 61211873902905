import React from 'react'
import LoanForm from '../../../components/sections/loan/LoanForm'
import { useParams } from 'react-router-dom'
import useGetSingleLoadDetail from '../../../hooks/Loan/useGetSingleLoadDetail'
import LoanDelete from './LoanDelete'

const EditLoan = () => {
  const { username,loanId } = useParams() 
  const {responseData} = useGetSingleLoadDetail({id:loanId})
  return (
    <div className='px-6'>
      <div className=' flex items-center justify-between'>
        <h2 className=' text-xl font-semibold mb-5 underline'>Edit Loan for {username}</h2>
        <LoanDelete/>
      </div>
      <LoanForm formData={responseData?.data?.data}/>
    </div>
  )
}

export default EditLoan