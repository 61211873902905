import usePut from "../usePut";

const useUpdateTransaction = () => {
  const { handleAuth: updateReq } = usePut({
    url: "create_petty_cash_transaction",
    onSuccess: (res) => {},
  });

  const updateTransactions = (pettyCashId, transactionData) => {
    updateReq.mutate({
      petty_cash_id: pettyCashId,
      expenses_data: transactionData,
    });
  };

  return { updateReq, updateTransactions };
};

export default useUpdateTransaction;
