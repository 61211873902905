import usePut from "../usePut";

const useDeleteTransaction = () => {
  const { handleAuth: deleteReq } = usePut({
    url: "delete_petty_cash_transaction",
    onSuccess: () => {},
  });

  const deleteTransaction = (pettyCashId, transactionId) => {
    deleteReq.mutate({
      petty_cash_id: pettyCashId,
      transaction_id: transactionId,
    });
  };

  return { deleteReq, deleteTransaction };
};

export default useDeleteTransaction;
