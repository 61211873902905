import toast from "react-hot-toast";
import usePut from "../put/usePut";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ROLE, TOKEN_NAME } from "../../config";
import { throwError } from "../../utility/errorHandler";

const cookies = new Cookies();
const useBasicLogin = () => {
  let navigate = useNavigate();
  const { handleAuth: loginUser } = usePut({
    url: "login_user",
    onSuccess: (res) => {
      toast.success("Login Successfully");
      const { token, role } = res?.data?.data;
      cookies.set(TOKEN_NAME, token, { path: "/" });
      cookies.set(ROLE, role, { path: "/" });
      navigate("/", { replace: true });
    },
    onError: (err) => {
      throwError(err);
      if (err.response.status === 400) {
        toast.error("Email and/or password incorrect");
      }
    },
  });

  const handleLoginUser = (data) => {
    // console.log(data);
    loginUser.mutate(data);
  };

  return { loginUser, handleLoginUser };
};

export default useBasicLogin;
