import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import useRead from '../../hooks/useRead'
import LeavesCalculation from './LeavesCalculation'
import { useForm } from 'react-hook-form'
import usePut from '../../hooks/usePut'
import { calculateFractionOfFinancialYear } from '../../utility'
import PrimaryLoader from '../../components/common/PrimaryLoader'
import { FULL_TIME_EMPLOYEE_LEAVES, ON_SITE_EMPLOYEE_LEAVES, PART_TIME_EMPLOYEE_LEAVES, REMOTE_EMPLOYEE_LEAVES } from '../../config'
import PreviousLeaves from './PreviousLeaves'
import toast, { Toaster } from 'react-hot-toast'

const EmployeePromotion = () => {
    const { control, setValue, watch, handleSubmit } = useForm()
    const navigate = useNavigate()
    const initialEmployeePromotionState = {
        employment_id: '',
        shift_id: '',
        from_date: '',
        employment_type: '',
        employeeType: ''
    }
    const [employeePromotionForm, setEmployeePromotionForm] = useState(initialEmployeePromotionState)
    const { id, username } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const { list: promotionDetail } = useRead({
        url: 'getPromotionDetails',
        initialData: {
            user_id: id
        },
        onSuccess: (res) => {
            setEmployeePromotionForm(prev => {
                return {
                    ...prev,
                    employment_id: res.currentPromotion.employment_id,
                    shift_id: res.currentPromotion.shift_id,
                    promotion_type: res.currentPromotion.promotion_type,
                    employment_type: res.currentPromotion.employment_type,
                    employeeType: res.currentPromotion.user_type
                }
            })
            setValue('leaves.annual', res.currentPromotion.applicableLeaves?.annual_ol)
            setValue('leaves.available', res.currentPromotion.applicableLeaves?.pending_ol_leave)
        }
    })
    const { list: employmentList } = useRead({
        url: 'employment_listing',
        initialData: {}
    })
    const { list: shiftList } = useRead({
        url: 'shift_listing',
        method: 'GET',
        initialData: {
            limit: 50,
            search_query: "",
        },
    })
    const { handleAuth } = usePut({
        url: 'createPromotion',
        onSuccess: () => {
            toast.success('Employee Promotion Updated')
        },
        onError: (err) => {
            const { response } = err
            if (response.status === 400) {
                alert(Object.keys(response.data.data).map(key => {
                    return response.data.data[key]
                }))
            } else if (response.status === 500) {
                toast.error('There Was An Error While Updating please try again later')
            }
        }
    })
    const onChangeLeaveCalculation = (value, type) => {
        let numberOfDaysRemaining = calculateFractionOfFinancialYear(new Date(employeePromotionForm.from_date))
        let availLeave = Math.round((+value / 365) * numberOfDaysRemaining);
        setValue(type, availLeave)
    }
    const handleChangeEffectFromDate = (e) => {
        let date = e.target.value
        setEmployeePromotionForm(prev => {
            return {
                ...prev,
                from_date: date
            }
        })
        setLeaves(date, employeePromotionForm.employment_type)
        let numberOfDaysRemaining = calculateFractionOfFinancialYear(new Date(date))
        let availLeave1 = Math.round((watch('leaves.pl.annual') / 365) * numberOfDaysRemaining);
        let availLeave2 = Math.round((watch('leaves.cl.annual') / 365) * numberOfDaysRemaining);
        let availLeave3 = Math.round((watch('leaves.sl.annual') / 365) * numberOfDaysRemaining);
        setValue('leaves.pl.available', availLeave1)
        setValue('leaves.cl.available', availLeave2)
        setValue('leaves.sl.available', availLeave3)
    }

    const updatePromotion = () => {
        const promotionData = (data) => {
            let formData = {
                ...data,
                ...employeePromotionForm,
                user_id: id
            }
            // console.log(formData);
            handleAuth.mutate(formData)
        }
        handleSubmit(promotionData)()
    }
    const handleSetFormField = (e) => {
        let value = e.target.value
        let name = e.target.name
        if (e.target.name === 'employment_id') {
            const selectElement = e.target;
            const selectedOption = selectElement.options[selectElement.selectedIndex];
            const selectedText = selectedOption.getAttribute("data-tag");
            const employeeType = selectedOption.getAttribute("data-employee");
            setLeaves(employeePromotionForm.from_date, selectedText)
            setEmployeePromotionForm(prev => {
                return {
                    ...prev,
                    employment_type: selectedText,
                    employeeType
                }
            })
        }
        setEmployeePromotionForm(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const setLeaves = (value, text) => {
        // if (!value) return
        // if (text === 'Full Time') {
        //     setValue('leaves.pl.annual', FULL_TIME_EMPLOYEE_LEAVES.pl)
        //     onChangeLeaveCalculation(FULL_TIME_EMPLOYEE_LEAVES.pl, 'leaves.pl.available', value)
        //     setValue('leaves.cl.annual', FULL_TIME_EMPLOYEE_LEAVES.cl)
        //     onChangeLeaveCalculation(FULL_TIME_EMPLOYEE_LEAVES.cl, 'leaves.cl.available', value)
        //     setValue('leaves.sl.annual', FULL_TIME_EMPLOYEE_LEAVES.sl)
        //     onChangeLeaveCalculation(FULL_TIME_EMPLOYEE_LEAVES.sl, 'leaves.sl.available', value)
        // } else if (text === 'Part Time') {
        //     setValue('leaves.pl.annual', PART_TIME_EMPLOYEE_LEAVES.pl)
        //     onChangeLeaveCalculation(PART_TIME_EMPLOYEE_LEAVES.pl, 'leaves.pl.available', value)
        //     setValue('leaves.cl.annual', PART_TIME_EMPLOYEE_LEAVES.cl)
        //     onChangeLeaveCalculation(PART_TIME_EMPLOYEE_LEAVES.cl, 'leaves.cl.available', value)
        //     setValue('leaves.sl.annual', PART_TIME_EMPLOYEE_LEAVES.sl)
        //     onChangeLeaveCalculation(PART_TIME_EMPLOYEE_LEAVES.sl, 'leaves.sl.available', value)
        // }
    }
    return (
        <>
            <Toaster />
            {handleAuth.isLoading &&
                <div className=' fixed top-0 left-0 h-full w-full bg-[#00000021] flex items-center justify-center z-50'>
                    <PrimaryLoader />
                </div>}
            <div className=' px-4 py-4 sticky bg-white top-16 shadow-lg z-40'>
                <div className='flex items-center justify-between space-x-4'>
                    <div className='flex items-center space-x-4'>
                        {/* <button onClick={() => navigate(-1, { replace: true })} className='flex items-center hover:underline'>
                            <ChevronLeftIcon className='w-6 h-6' />
                            <span className='font-semibold'>Back</span>
                        </button> */}
                        <h2 className='text-2xl font-semibold'>Promoting {username}</h2>
                    </div>
                    <div className=' space-x-5'>
                        <button onClick={updatePromotion} className='px-5 py-2 bg-yellow-300 rounded font-semibold'>Save Detail</button>
                    </div>
                </div>
            </div>
            {promotionDetail.isLoading
                ? <div className=' flex items-center justify-center mt-10'> <PrimaryLoader /></div>
                : <div className='px-5 my-10'>
                    <div className='grid grid-cols-4 gap-5 py-5'>
                        <div>
                            <div>
                                <label className='label'>Current Type </label>
                                <span className='input block font-semibold'>
                                    {employeePromotionForm.promotion_type}
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label className='label'>New Type? </label>
                                <select name="employment_id" className='input' value={employeePromotionForm.employment_id} onChange={handleSetFormField}>
                                    <option value="">--Select New Type--</option>
                                    {employmentList.data?.data.map((item) => {
                                        const { type_of_employee } = item
                                        return <option data-employee={type_of_employee} key={item.id} value={item.id} data-tag={item?.tag} className=' capitalize'>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label className='label'>Assign Shift </label>
                                <select name="shift_id" className='input' value={employeePromotionForm.shift_id} onChange={handleSetFormField}>
                                    <option value="">--Select Shift--</option>
                                    {shiftList.data?.data.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.shiftName}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label className='label'>Effect From </label>
                                <input onChange={handleChangeEffectFromDate} type="date" name="from_date" id="" className='input' value={employeePromotionForm.from_date} />
                            </div>
                        </div>
                    </div>
                    <div className=' grid grid-cols-2 gap-5'>
                        <div>
                            <label className='label'>Previous Leaves: </label>
                            <div className=' border border-gray-200 rounded-lg p-4'>
                                <PreviousLeaves
                                    employeeType={employeePromotionForm.employeeType}
                                    type={employeePromotionForm.employment_type}
                                    data={promotionDetail.data?.currentPromotion}
                                />
                            </div>
                        </div>
                        <div className=''>
                            <label className='label'>Assign New Leaves: </label>
                            <div className=' border border-gray-200 rounded-lg p-4'>
                                <LeavesCalculation
                                    control={control}
                                    employeeType={employeePromotionForm.employeeType}
                                    onChangeText={onChangeLeaveCalculation}
                                    type={employeePromotionForm.employment_type}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default EmployeePromotion