import { useMutation } from "@tanstack/react-query";
import axios from "../api/axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
import toast from "react-hot-toast";
const cookies = new Cookies();

function useDownloadFile({ url, filename }) {
  const reportDownload = useMutation((data) => downloadReports(data), {
    onSuccess: (data) => {
      const href = URL.createObjectURL(new Blob([data.data]));
      let a = document.createElement("a");
      a.href = href;
      a.download = filename;
      a.click();
    },
    onError: () => {
      toast.error("Some error occurred");
    },
  });

  const downloadReports = async (data) => {
    let TOKEN = cookies.get(TOKEN_NAME);
    let fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    let res = await axios({
      method: "post",
      url: `/${url}`,
      responseType: "blob",
      data: fd,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : "" },
    });
    return res;
  };

  return { reportDownload };
}

export default useDownloadFile;
