import axios from "axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME, config } from "../config";
const cookies = new Cookies();

export const getAttendanceList = async (data) => {
    console.log(data)
    let token = cookies.get(TOKEN_NAME); 
    var form_data = new FormData();   
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}singleuserattendancelist`, data:form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const getsingleDayWiseUsersAttendanceData = async (data) => {
    console.log(data)
    let token = cookies.get(TOKEN_NAME); 
    var form_data = new FormData();   
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}singleDayWiseUsersAttendanceData`, data:form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const saveUpdateAttendance = async (data,action,attendanceId = '') => {
    // console.log({data,action,attendanceId})
    let token = cookies.get(TOKEN_NAME); 
    var form_data = new FormData();  
    for (var key in data) {
        if(key === 'id'){
            data.id && form_data.append('id', data.id);
        }else{
            form_data.append(key, data[key]);
        }
    }
    form_data.append('action', action);
    attendanceId && form_data.append('id', attendanceId);
    var res = await axios({ method: "POST", url: `${config.apiurl}saveandupdateattendance`, data:form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};