import React, { useState } from "react";
import Pagination from "../../components/pagination";
import useGetSalarySlips from "../../hooks/salary/useGetSalarySlips";
import { useSearchParams } from "react-router-dom";
import useDownloadFile from "../../hooks/useDownloadFile";
import { Toaster } from "react-hot-toast";
import { checkIfEmpty, checkIfNotEmpty } from "../../utility/utils";

const months = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

const SalarySlips = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, paramsObject, setGetListParams } = useGetSalarySlips?.({
    page_no: searchParams.get("page_no") || 0,
  });
  const data = list?.data;
  const slipsData = list?.data?.data;

  const [currentMonthYear, setCurrentMonthYear] = useState();

  const { reportDownload } = useDownloadFile({
    url: "single_user_salary_slip",
    filename: "salary_slip_" + currentMonthYear + ".pdf",
  });

  const downloadSlip = (monthYear) => {
    setCurrentMonthYear(monthYear);
    reportDownload.mutate({
      month_year: monthYear,
    });
  };

  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_no: val.selected,
    };
    setGetListParams(data);
    setSearchParams(data);
  };

  return (
    <div className="p-5">
      <Toaster />
      <h1 className="font-semibold text-2xl mb-10">Salary Slips</h1>

      <table class="table-auto w-full">
        {checkIfNotEmpty(slipsData) &&
          slipsData.length !== 0 &&
          !list?.isLoading && (
            <thead>
              <tr class=" bg-[color:var(--color1)] text-center">
                <th class="w-2/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent hidden lg:table-cell">
                  Sr.No
                </th>
                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                  Month
                </th>
                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                  Year
                </th>
                <th></th>
              </tr>
            </thead>
          )}

        <tbody>
          {slipsData?.map((el, idx) => {
            return (
              <tr key={idx}>
                <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] hidden lg:table-cell">
                  {idx + 1}
                </td>
                <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] table-cell">
                  {months[el.month]}
                </td>
                <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] table-cell">
                  {el.year}
                </td>
                <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] table-cell">
                  <button
                    className="bg-primary px-6 py-1 rounded-md text-white"
                    onClick={() => downloadSlip(el.month_year)}
                  >
                    Download Slip
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {(checkIfEmpty(slipsData) || slipsData.length == 0) &&
        !list.isLoading && (
          <div className="py-16 grid place-items-center">
            <p>No salary slips generated</p>
          </div>
        )}

      <div className="px-5 mt-5">
        <Pagination
          currentPage={data?.page_no}
          lengthofItems={data?.total_count}
          limit={data?.per_page}
          onPageChange={handlePageClick}
        />
      </div>
    </div>
  );
};

export default SalarySlips;
