import useGet from "../read/useGet";

const useGetSalarySlips = (initialData) => {
  const { list, paramsObject, setGetListParams } = useGet({
    url: "user_salary_slip_listing",
    initialData: initialData,
  });

  return { list, paramsObject, setGetListParams };
};

export default useGetSalarySlips;
