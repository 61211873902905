import { useRef, useState } from "react";
import useGetExpencesTypeList from "../../hooks/PettyCash/useGetExpencesTypeList";
import { MdDelete } from "react-icons/md";
import Modals from "../../components/modal/Modals";
import toast from "react-hot-toast";
import { debounce } from "lodash";
import useUpdateTransaction from "../../hooks/PettyCash/useUpdateTransaction";
import useGetTransactions from "../../hooks/PettyCash/useGetTransactions";
import useDownloadTransactionReport from "../../hooks/PettyCash/useDownloadTransactionReport";
import useDeleteTransaction from "../../hooks/PettyCash/useDeleteTransaction";
import useCreateTransaction from "../../hooks/PettyCash/useCreateTransaction";
import { config } from "../../config";

const TransactionTable = ({ pettyCashId }) => {
  const { responseData } = useGetExpencesTypeList();

  const [currentDeleteId, setCurrentDeleteId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const { responseData: transactionsRes } = useGetTransactions({
    pettyCashId: pettyCashId,
    onSuccess: (res) => {
      setTransactions(res?.data || []);
    },
  });

  const { updateTransactions } = useUpdateTransaction();
  const { createTransactions } = useCreateTransaction({
    onSuccess: (res) => {
      transactionsRes.refetch();
    },
  });
  const { deleteTransaction } = useDeleteTransaction();
  const { downloadReport } = useDownloadTransactionReport();

  const addTransaction = () => {
    const today = new Date();

    const newTransaction = [
      ...transactions,
      {
        id: transactions.length + 1,
        transaction_id: "",
        expenses_date:
          today?.getFullYear() +
          "-" +
          (today?.getMonth() < 9 ? "0" : "") +
          (today?.getMonth() + 1) +
          "-" +
          (today?.getDate() < 10 ? "0" : "") +
          today.getDate(),
        particulars: "",
        expenses_type_id: responseData?.data?.data[0].id,
        amount: 0,
      },
    ];

    setTransactions(newTransaction);

    // let arr = [];
    // // Filtering new transactions
    // for (let i = 0; i < newTransaction.length; i++) {
    //   let txnId = newTransaction[i].transaction_id;
    //   if (txnId === "") {
    //     arr.push(newTransaction[i]);
    //   }
    // }

    createTransactions(pettyCashId, newTransaction);
  };

  const onFieldChanged = (e, id, name) => {
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (name === "amount") {
      if (!regex.test(e.target.value)) {
        return;
      }
    }

    let arr = transactions.map((el, idx) => {
      if (id === idx) {
        let obj = { ...el };
        obj[name] = e.target.value;
        return obj;
      } else {
        return el;
      }
    });

    setTransactions(arr);

    if (name === "expenses_date" || name === "expenses_type_id") {
      saveTransactions(arr, id);
    } else {
      debouncedSave(arr, id);
    }
  };

  const openDeleteConfirmation = (transactionId) => {
    setCurrentDeleteId(transactionId);
    setOpenDeleteModal(true);
  };

  const debouncedSave = useRef(
    debounce((val, id) => {
      saveTransactions(val, id);
    }, 2500)
  ).current;

  const saveTransactions = (val, id) => {
    // let arr = [];
    let isEdit = false;

    // // Filtering transaction to edit
    // for (let i = 0; i < val.length; i++) {
    //   let txnId = val[i].transaction_id;
    //   if (id === val[i].id && txnId !== "") {
    //     isEdit = true;
    //     arr.push(val[i]);
    //     break;
    //   }
    // }

    // // Filtering new transactions
    // if (!isEdit) {
    //   for (let i = 0; i < val.length; i++) {
    //     let txnId = val[i].transaction_id;
    //     if (txnId === "") {
    //       arr.push(val[i]);
    //     }
    //   }
    // }

    updateTransactions(pettyCashId, val);
  };

  const downloadTransactionReport = () => {
    downloadReport(pettyCashId);
  };

  const deleteRow = () => {
    let arr = transactions.filter((el, idx) => {
      if (currentDeleteId !== el?.transaction_id) {
        return el;
      }
    });

    setTransactions(arr);
    setOpenDeleteModal(false);
    toast.success("Row deleted");

    deleteTransaction(pettyCashId, currentDeleteId);
  };

  return (
    <>
      <div className="px-4 py-4 border-t border-gray-400 flex items-center">
        <h2 className=" text-2xl text-center font-bold">
          Petty Cash Expense Details
        </h2>
        {/* <a
          className=" bg-gray-200 px-4 py-2 rounded ml-auto mr-4"
          href={`${config.apiurl}petty_cash_transaction_report?petty_cash_id=${pettyCashId}`}
          download
          target="_blank"
        > 
        </a> 
        */}
        <button
          className=" bg-gray-200 px-4 py-2 rounded ml-auto mr-4"
          onClick={downloadTransactionReport}
        >
          Download Report
        </button>

        <button
          className=" bg-yellow-400 px-4 py-2 rounded"
          onClick={addTransaction}
        >
          Add Transaction
        </button>
      </div>
      <Modals show={openDeleteModal}>
        <div className="">
          <h4 className="text-xl font-semibold mb-4">Delete row</h4>
          <p className="mb-4">Are you sure you wish to delete this row?</p>
          <div className="flex items-center w-full">
            <button
              className="bg-color7 text-white px-5 py-2 rounded-md mr-4"
              onClick={() => setOpenDeleteModal(false)}
            >
              No
            </button>
            <button
              className="bg-primary text-white px-5 py-2 rounded-md"
              onClick={deleteRow}
            >
              Yes
            </button>
          </div>
        </div>
      </Modals>
      {/* <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
       absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] p-5
      </Modal> */}
      <div className="px-5 py-10">
        {transactions.length > 0 && (
          <table className="w-full">
            <thead>
              <tr>
                <td className="p-3 bg-primary text-white border border-gray-300 font-semibold text-center">
                  Date
                </td>
                <td className="p-3 bg-primary text-white border border-gray-300 font-semibold text-center">
                  Particulars
                </td>
                <td className="p-3 bg-primary text-white border border-gray-300 font-semibold text-center">
                  Type of transaction
                </td>
                <td className="p-3 bg-primary text-white border border-gray-300 font-semibold text-center">
                  Amount
                </td>
                <td className="p-3 bg-primary text-white border border-gray-300 font-semibold text-center"></td>
              </tr>
            </thead>
            <tbody>
              {transactions.map((el, idx) => {
                return (
                  <tr>
                    <td className="p-3 border border-gray-300">
                      <input
                        type="date"
                        className="border w-full p-1"
                        value={el.expenses_date}
                        onChange={(e) =>
                          onFieldChanged(e, idx, "expenses_date")
                        }
                      />
                    </td>
                    <td className="p-3 border border-gray-300">
                      <input
                        type="text"
                        className="border w-full p-1"
                        value={el.particulars}
                        onChange={(e) => onFieldChanged(e, idx, "particulars")}
                      />
                    </td>
                    <td className="p-3 border border-gray-300 w-1/4">
                      <select
                        className="p-1 w-full border"
                        value={el.expenses_type_id}
                        onChange={(e) =>
                          onFieldChanged(e, idx, "expenses_type_id")
                        }
                      >
                        <option disabled>Select type</option>
                        {responseData?.data?.data?.map((opt) => {
                          return (
                            <option
                              value={opt.id}
                              selected={opt.id === el.expenses_type_id}
                            >
                              {opt.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td className="p-3 border border-gray-300 w-1/6">
                      <input
                        type="text"
                        className="border w-full p-1"
                        value={el.amount}
                        onChange={(e) => onFieldChanged(e, idx, "amount")}
                      />
                    </td>
                    <td className="p-3 border border-gray-300 ">
                      <button
                        className="grid place-items-center w-full"
                        onClick={() =>
                          openDeleteConfirmation(el?.transaction_id)
                        }
                      >
                        <MdDelete className="text-red-600 w-7 h-7" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default TransactionTable;
