import { useForm } from "react-hook-form";
import { Input } from "../ui";
import { RxReload } from "react-icons/rx";
import useBasicLogin from "../../hooks/auth/useBasicLogin";
import { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";

const UsernamePassword = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [passwordInputType, setPasswordInputType] = useState("password");

  const { handleLoginUser, loginUser } = useBasicLogin();

  const changePasswordType = () => {
    setPasswordInputType(passwordInputType === "text" ? "password" : "text");
  };

  return (
    <div className="w-[69.5%] space-y-4">
      <Input
        control={control}
        label={"Email"}
        name={"email"}
        placeholder={"Please Enter Email"}
        rules={{
          required: "Email is required",
        }}
      />
      <div className="relative">
        <Input
          control={control}
          label={"Password"}
          type={passwordInputType}
          name={"password"}
          placeholder={"Please Enter Password"}
          rules={{
            required: "Password is required",
          }}
        />
        <button
          className="absolute right-0 bottom-0 mb-1.5 mr-3"
          onClick={changePasswordType}
        >
          {passwordInputType === "password" ? (
            <FaEye className="text-black w-6 h-6" />
          ) : (
            <FaEyeSlash className="text-black w-6 h-6" />
          )}
        </button>
      </div>

      <button
        onClick={handleSubmit(handleLoginUser)}
        disabled={loginUser.isLoading}
        className=" bg-primary w-full rounded-md py-2 text-white"
      >
        <div className=" flex items-center justify-center space-x-3">
          <span>Login</span>
          {loginUser.isLoading && <RxReload className=" animate-spin" />}
        </div>
      </button>
    </div>
  );
};

export default UsernamePassword;
