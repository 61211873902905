import usePut from "../usePut";

const useCreateTransaction = ({ onSuccess = () => {} }) => {
  const { handleAuth: createReq } = usePut({
    url: "create_petty_cash_transaction",
    onSuccess: (res) => {
      onSuccess?.(res);
    },
  });

  const createTransactions = (pettyCashId, transactionData) => {
    createReq.mutate({
      petty_cash_id: pettyCashId,
      expenses_data: transactionData,
    });
  };

  return { createReq, createTransactions };
};

export default useCreateTransaction;
