import { useEmployeeContext } from "../../providers/EditEmployeeProvider"
import { generatePasswordNew } from "../../utility"
import { Input } from "../ui"

const GeneratePassword = ({
    label,
    name,
    placeholder,
    rules
}) => {
    const [control, handleSubmit, setValue] = useEmployeeContext()
    const handleGeneratePassword = () => {
        const options = {
            lowercase: true,
            uppercase: true,
            digits: true,
            specialChars: true
        };
        const password = generatePasswordNew(16, options)
        setValue(name,password)
    }
    return (
        <div className="flex items-end space-x-4">
            <div className="w-full">
                <Input
                    control={control}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    rules={rules}
                />
            </div>
            <button onClick={handleGeneratePassword} type="button" className="text-sm w-[40%] bg-red-500 border border-red-500 text-white h-9 rounded">Generate Password</button>
        </div>
    )
}

export default GeneratePassword