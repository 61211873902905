import PettyCashForm from '../../components/sections/PettyCash/PettyCashForm';
import TransactionTable from './TransactionTable';

const PettyCashCreate = ({
}) => {
  return (
    <>
      <div className='px-4 py-4'>
        <PettyCashForm />
      </div>
      <div>
        <TransactionTable/>
      </div>
    </>
  )
}

export default PettyCashCreate