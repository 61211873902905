import React, { useState } from "react";
import Modals from "./Modals";
import { XIcon } from "@heroicons/react/solid";
import DatePicker from "react-datepicker";
import useDownloadFile from "../../hooks/useDownloadFile";
import toast from "react-hot-toast";

const DownloadAttendanceModal = ({ show, setShow }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);

  const { reportDownload } = useDownloadFile({
    url: "muster_attendance_report",
    filename:
      "attendance_report-" +
      (selectedMonth?.getMonth() + 1) +
      "-" +
      selectedMonth?.getFullYear() +
      ".xlsx",
  });

  const downloadReport = () => {
    if (selectedMonth) {
      let data = {
        month_year: selectedMonth,
      };
      reportDownload.mutate(data);
    } else {
      toast.error("Please select month/year");
    }
  };

  return (
    <Modals show={show}>
      <div className="flex mb-5 items-center">
        <h2 className="text-xl  roboto font-bold ">Download Report</h2>

        <button className="ml-auto" onClick={() => setShow(false)}>
          <XIcon className="w-6 h-6" />
        </button>
      </div>
      <label className=" roboto label">Select Month/Year</label>
      <DatePicker
        required
        name="month_and_year"
        autoComplete="false"
        className="input"
        dateFormat="MM/yyyy"
        maxDate={new Date()}
        showMonthYearPicker
        selected={selectedMonth}
        onChange={(value) => setSelectedMonth(value)}
      />

      <div className="flex justify-center">
        <button
          type="button"
          onClick={downloadReport}
          className="mt-5 mx-auto bg-[color:var(--color1)] text-white rounded px-4 py-2"
        >
          Download
        </button>
      </div>

      <div>
        {reportDownload.isLoading && <p>Downloading report... Please wait</p>}
      </div>
    </Modals>
  );
};

export default DownloadAttendanceModal;
