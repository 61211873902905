import axios from "axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME, config } from "../config";
const cookies = new Cookies();

export const getMetaData = async () => {
    let token = cookies.get(TOKEN_NAME);
    var res = await axios({ method: "GET", url: `${config.apiurl}leaveapplicationcountadmin`, headers: { 'Content-Type': 'application/x-www-form-urlencoded',Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const getUserName = async (typeOfEmployee) => {
    let token = cookies.get(TOKEN_NAME);
    var form_data = new FormData();
    form_data.append('type_of_employee', typeOfEmployee);
    var res = await axios({ method: "POST", url: `${config.apiurl}fetchusername`, data:form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const getUserLeaveData = async (id = '', page_no = 0, limit = 10,month_year) => {
    let token = cookies.get(TOKEN_NAME);
    var form_data = new FormData();
    form_data.append('id', id);
    form_data.append('page_no', page_no);
    form_data.append('limit', limit );
    form_data.append('month_year', month_year );
    var res = await axios({ method: "POST", url: `${config.apiurl}leavelisting`, data:form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};

export const downloadReport = async (data) => {
    let token = cookies.get(TOKEN_NAME);
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", responseType:'blob',data:form_data, url: `${config.apiurl}salary_report`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};

export const downloadUserReport = async () => {
    let token = cookies.get(TOKEN_NAME);
    var res = await axios({ method: "GET", responseType:'blob', url: `${config.apiurl}users_list`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};



